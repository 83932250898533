import { DecisionReason } from "../../model/model"
import FlowEvaluator from "./FlowEvaluator"
import ExperimentRequest from "../evalautor/experiment/ExperimentRequest"
import { EvaluatorContext } from "../evalautor/Evaluator"
import ExperimentEvaluation from "../evalautor/experiment/ExperimentEvaluation"

export default class EvaluationFlow {

  readonly flowEvaluator: FlowEvaluator | undefined
  readonly nextFlow: EvaluationFlow | undefined

  constructor(flowEvaluator: FlowEvaluator | undefined = undefined, nextFlow: EvaluationFlow | undefined = undefined) {
    this.flowEvaluator = flowEvaluator
    this.nextFlow = nextFlow
  }

  evaluate(request: ExperimentRequest, context: EvaluatorContext): ExperimentEvaluation {
    if (this.flowEvaluator && this.nextFlow) {
      return this.flowEvaluator.evaluate(request, context, this.nextFlow)
    } else {
      return ExperimentEvaluation.ofDefault(request, context, DecisionReason.TRAFFIC_NOT_ALLOCATED)
    }
  }

  static of(...evaluators: FlowEvaluator[]): EvaluationFlow {
    let flow = new EvaluationFlow()
    for (const flowEvaluator of evaluators.reverse()) {
      flow = new EvaluationFlow(flowEvaluator, flow)
    }
    return flow
  }
}
