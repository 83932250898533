import {
  Bucket,
  BucketId,
  Container,
  ContainerId,
  EventKey,
  EventType,
  Experiment,
  ExperimentKey,
  IAM,
  ParameterConfiguration,
  ParameterConfigurationId,
  RemoteConfigParameter,
  RemoteConfigParameterKey,
  Segment,
  SegmentKey
} from "../model/model"
import { WorkspaceDto } from "./dto"
import CollectionUtil from "../util/CollectionUtil"

export default class Workspace {
  private experiments: Map<ExperimentKey, Experiment>
  private featureFlags: Map<ExperimentKey, Experiment>
  private buckets: Map<BucketId, Bucket>
  private eventTypes: Map<EventKey, EventType>
  private segments: Map<SegmentKey, Segment>
  private containers: Map<ContainerId, Container>
  private parameterConfigurations: Map<ParameterConfigurationId, ParameterConfiguration>
  private remoteConfigParameters: Map<RemoteConfigParameterKey, RemoteConfigParameter>
  private inAppMessages: IAM[]

  constructor(
    experiments: Map<ExperimentKey, Experiment>,
    featureFlags: Map<ExperimentKey, Experiment>,
    buckets: Map<BucketId, Bucket>,
    eventTypes: Map<EventKey, EventType>,
    segments: Map<SegmentKey, Segment>,
    containers: Map<ContainerId, Container>,
    parameterConfigurations: Map<ParameterConfigurationId, ParameterConfiguration>,
    remoteConfigParameters: Map<RemoteConfigParameterKey, RemoteConfigParameter>,
    inAppMessages: IAM[]
  ) {
    this.experiments = experiments
    this.featureFlags = featureFlags
    this.buckets = buckets
    this.eventTypes = eventTypes
    this.segments = segments
    this.containers = containers
    this.parameterConfigurations = parameterConfigurations
    this.remoteConfigParameters = remoteConfigParameters
    this.inAppMessages = inAppMessages
  }

  getInAppMessages(): IAM[] {
    return this.inAppMessages
  }

  getExperiments() {
    return Array.from(this.experiments.values())
  }

  getExperimentOrNull(experimentKey: ExperimentKey): Experiment | undefined {
    return this.experiments.get(experimentKey)
  }

  getFeatureFlags() {
    return Array.from(this.featureFlags.values())
  }

  getFeatureFlagOrNull(featureKey: ExperimentKey): Experiment | undefined {
    return this.featureFlags.get(featureKey)
  }

  getBucketOrNull(bucketId: BucketId): Bucket | undefined {
    return this.buckets.get(bucketId)
  }

  getEventTypeOrNull(eventKey: EventKey): EventType | undefined {
    return this.eventTypes.get(eventKey)
  }

  getSegmentOrNull(segmentKey: SegmentKey): Segment | undefined {
    return this.segments.get(segmentKey)
  }

  getContainerOrNull(containerId: ContainerId): Container | undefined {
    return this.containers.get(containerId)
  }

  getParameterConfigurationOrNull(
    parameterConfigurationId: ParameterConfigurationId
  ): ParameterConfiguration | undefined {
    return this.parameterConfigurations.get(parameterConfigurationId)
  }

  getRemoteConfigParameterOrNull(remoteConfigParameterKey: string): RemoteConfigParameter | undefined {
    return this.remoteConfigParameters.get(remoteConfigParameterKey)
  }

  getInAppMessageOrNull(messageKey: number) {
    return this.inAppMessages.find((msg) => msg.key === messageKey)
  }

  static from(dto: WorkspaceDto): Workspace {
    const buckets = CollectionUtil.associate(dto.buckets, (it) => [it.id, Bucket.fromJSON(it)])

    const experiments: Map<ExperimentKey, Experiment> = CollectionUtil.associateBy(
      CollectionUtil.mapNotNullOrUndefined(dto.experiments, (it) => Experiment.fromJSON("AB_TEST", it)),
      (it) => it.key
    )

    const featureFlags: Map<ExperimentKey, Experiment> = CollectionUtil.associateBy(
      CollectionUtil.mapNotNullOrUndefined(dto.featureFlags, (it) => Experiment.fromJSON("FEATURE_FLAG", it)),
      (it) => it.key
    )

    const eventTypes = CollectionUtil.associate(dto.events, (it) => [it.key, new EventType(it.id, it.key)])

    const segments: Map<SegmentKey, Segment> = CollectionUtil.associateBy(
      CollectionUtil.mapNotNullOrUndefined(dto.segments, (it) => Segment.fromJSON(it)),
      (it) => it.key
    )

    const containers: Map<ContainerId, Container> = CollectionUtil.associate(dto.containers, (it) => [
      it.id,
      Container.fromJSON(it)
    ])

    const parameterConfigurations: Map<ParameterConfigurationId, ParameterConfiguration> = CollectionUtil.associate(
      dto.parameterConfigurations,
      (it) => [it.id, ParameterConfiguration.fromJSON(it)]
    )

    const remoteConfigParameters: Map<RemoteConfigParameterKey, RemoteConfigParameter> = CollectionUtil.associateBy(
      CollectionUtil.mapNotNullOrUndefined(dto.remoteConfigParameters, (it) => RemoteConfigParameter.fromJSON(it)),
      (it) => it.key
    )

    const inAppMessages = CollectionUtil.mapNotNullOrUndefined(dto.inAppMessages, (message) => IAM.fromJSON(message))

    return new Workspace(
      experiments,
      featureFlags,
      buckets,
      eventTypes,
      segments,
      containers,
      parameterConfigurations,
      remoteConfigParameters,
      inAppMessages
    )
  }
}
