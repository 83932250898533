import { IAM } from "../../model/model"
import { EvaluatorContext } from "../evalautor/Evaluator"
import { TrackInAppMessageRequest } from "../evalautor/iam/TrackInAppMessageRequest"
import InAppMessageTargetMatcher from "../match/InAppMessageTargetMatcher"

export class TrackInAppMessageDeterminer {
  constructor(private readonly targetMather: InAppMessageTargetMatcher) {}

  public determineInAppMessage(request: TrackInAppMessageRequest, context: EvaluatorContext) {
    const inAppMessages = request.workspace.getInAppMessages()

    const triggeredInAppMessage = inAppMessages.reduce<{ message: IAM; priority: number } | null>((acc, message) => {
      const match = this.matches(request, context, message)

      if (!match) {
        return acc
      }

      if (!acc) {
        return match
      }

      if (acc.priority >= match.priority) {
        return acc
      }

      return match
    }, null)

    return triggeredInAppMessage?.message
  }

  private matches(request: TrackInAppMessageRequest, context: EvaluatorContext, message: IAM) {
    return message.eventTriggerRules.reduce<{ message: IAM; priority: number } | null>((acc, rule) => {
      if (rule.eventKey !== request.track.event.key) {
        return acc
      }

      if (rule.targets.length === 0) {
        if (acc && acc.priority >= rule.priority) {
          return acc
        }

        return { message, priority: rule.priority }
      }

      const targetRuleMatch = rule.targets.some((target) => this.targetMather.matches(request, context, target))

      if (!targetRuleMatch) {
        return acc
      }

      if (acc && acc.priority >= rule.priority) {
        return acc
      }

      return { message, priority: rule.priority }
    }, null)
  }
}
