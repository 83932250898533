import { v4 as uuid4 } from "uuid"
import {
  DecisionReason,
  EventType,
  Experiment,
  HackleEvent,
  HackleUser,
  IdentifierType,
  Long,
  Properties,
  RemoteConfigParameter,
  VariationId,
  VariationKey
} from "../model/model"
import { EventDto, ExposureEventDto, RemoteConfigEventDto, TrackEventDto } from "./dto"
import PropertyUtil from "../util/PropertyUtil"
import ExperimentEvaluation from "../evaluation/evalautor/experiment/ExperimentEvaluation"
import RemoteConfigEvaluation from "../evaluation/evalautor/remoteconfig/RemoteConfigEvaluation"

export default abstract class Event {
  timestamp: Long
  user: HackleUser
  public readonly insertId: string

  protected constructor(timestamp: Long, user: HackleUser, insertId: string = uuid4()) {
    this.timestamp = timestamp
    this.user = user
    this.insertId = insertId
  }

  static exposure(
    user: HackleUser,
    evaluation: ExperimentEvaluation,
    properties: Properties,
    timestamp: number
  ): Exposure {
    return new Exposure(
      timestamp,
      user,
      evaluation.experiment,
      evaluation.variationId,
      evaluation.variationKey,
      evaluation.reason,
      properties
    )
  }

  static track(eventType: EventType, event: HackleEvent, user: HackleUser, timestamp: number) {
    return new Track(timestamp, user, eventType, event)
  }

  static remoteConfig(
    user: HackleUser,
    evaluation: RemoteConfigEvaluation,
    properties: Properties,
    timestamp: number
  ): RemoteConfig {
    return new RemoteConfig(timestamp, user, evaluation.parameter, evaluation.valueId, evaluation.reason, properties)
  }

  static isExposure(event: Event): event is Exposure {
    return (event as Exposure).experiment !== undefined
  }

  static isTrack(event: Event): event is Track {
    return (event as Track).eventType !== undefined
  }

  static isRemoteConfig(event: Event): event is RemoteConfig {
    return (event as RemoteConfig).parameter !== undefined
  }

  static isExposureDto(event: EventDto): event is ExposureEventDto {
    return (
      "experimentId" in (event as ExposureEventDto) && typeof (event as ExposureEventDto).experimentId !== "undefined"
    )
  }

  static isTrackDto(event: EventDto): event is TrackEventDto {
    return "eventTypeId" in (event as TrackEventDto) && typeof (event as TrackEventDto).eventTypeId !== "undefined"
  }

  static isRemoteConfigDto(event: EventDto): event is RemoteConfigEventDto {
    return (
      "parameterId" in (event as RemoteConfigEventDto) &&
      typeof (event as RemoteConfigEventDto).parameterId !== "undefined"
    )
  }

  abstract copyWithUser(user: HackleUser): Event

  toDto(): EventDto {
    return {
      insertId: this.insertId,
      timestamp: this.timestamp,

      userId: this.user.identifiers[IdentifierType.ID],
      identifiers: this.user.identifiers,
      userProperties: PropertyUtil.sanitize(this.user.properties),
      hackleProperties: PropertyUtil.sanitize(this.user.hackleProperties)
    }
  }
}

export class Exposure extends Event {
  experiment: Experiment
  variationId?: VariationId
  variationKey: VariationKey
  decisionReason: DecisionReason
  properties: Properties

  constructor(
    timestamp: Long,
    user: HackleUser,
    experiment: Experiment,
    variationId: VariationId | undefined,
    variationKey: VariationKey,
    decisionReason: DecisionReason,
    properties: Properties,
    insertId?: string
  ) {
    super(timestamp, user, insertId)
    this.experiment = experiment
    this.variationId = variationId
    this.variationKey = variationKey
    this.decisionReason = decisionReason
    this.properties = properties
  }

  copyWithUser(user: HackleUser): Exposure {
    return new Exposure(
      this.timestamp,
      user,
      this.experiment,
      this.variationId,
      this.variationKey,
      this.decisionReason,
      this.properties,
      this.insertId
    )
  }

  toDto(): ExposureEventDto {
    return {
      ...super.toDto(),

      experimentId: this.experiment.id,
      experimentKey: this.experiment.key,
      experimentType: this.experiment.type,
      experimentVersion: this.experiment.version,
      variationId: this.variationId,
      variationKey: this.variationKey,
      decisionReason: this.decisionReason.toString(),
      properties: this.properties
    }
  }
}

export class Track extends Event {
  eventType: EventType
  event: HackleEvent

  constructor(timestamp: Long, user: HackleUser, eventType: EventType, event: HackleEvent, insertId?: string) {
    super(timestamp, user, insertId)
    this.eventType = eventType
    this.event = event
  }

  copyWithUser(user: HackleUser): Track {
    return new Track(this.timestamp, user, this.eventType, this.event, this.insertId)
  }

  toDto(): TrackEventDto {
    return {
      ...super.toDto(),

      eventTypeId: this.eventType.id,
      eventTypeKey: this.eventType.key,
      value: this.event.value || 0,
      properties: PropertyUtil.sanitize(this.event.properties)
    }
  }
}

export class RemoteConfig extends Event {
  parameter: RemoteConfigParameter
  valueId: number | undefined
  decisionReason: DecisionReason
  properties: Properties

  constructor(
    timestamp: Long,
    user: HackleUser,
    parameter: RemoteConfigParameter,
    valueId: number | undefined,
    decisionReason: DecisionReason,
    properties: Properties,
    insertId?: string
  ) {
    super(timestamp, user, insertId)
    this.parameter = parameter
    this.valueId = valueId
    this.decisionReason = decisionReason
    this.properties = properties
  }

  copyWithUser(user: HackleUser): RemoteConfig {
    return new RemoteConfig(
      this.timestamp,
      user,
      this.parameter,
      this.valueId,
      this.decisionReason,
      this.properties,
      this.insertId
    )
  }

  toDto(): RemoteConfigEventDto {
    return {
      ...super.toDto(),
      parameterId: this.parameter.id,
      parameterKey: this.parameter.key,
      parameterType: this.parameter.type,
      valueId: this.valueId,
      decisionReason: this.decisionReason.toString(),
      properties: PropertyUtil.sanitize(this.properties)
    }
  }
}
