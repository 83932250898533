import { Metric } from "./Metric"
import { MetricRegistry } from "./MetricRegistry"

export interface Counter extends Metric {
  count(): number

  increment(delta?: number): void
}

export class CounterBuilder {
  private _tags: { [key: string]: string } = {}

  constructor(private name: string) {}

  tags(tags: { [key: string]: string }) {
    Object.entries(tags).forEach(([key, value]) => {
      this._tags[key] = value
    })

    return this
  }

  tag(key: string, value: string) {
    this._tags[key] = value
    return this
  }

  register(registry: MetricRegistry) {
    return registry.registerCounter({ name: this.name, tags: this._tags, type: "COUNTER" })
  }
}

export function isCounterMetric(metric: Metric): metric is Counter {
  return metric.id.type === "COUNTER"
}
