import ObjectUtil from "../../core/internal/util/ObjectUtil"
import { HackleUserResolver } from "../user/index.browser"
import HackleCore from "../../core/HackleCore"

type GlobalErrorHandlerKey = "onerror" | "onunhandledrejection"
type Installation = (core: HackleCore, userResolver: HackleUserResolver) => void

export class GlobalErrorHandler {
  private _installed: Array<GlobalErrorHandlerKey> = []
  private _installations: Record<GlobalErrorHandlerKey, Installation> = {
    onerror: _installOnError,
    onunhandledrejection: _installOnUnhandledRejection
  }

  install(core: HackleCore, userResolver: HackleUserResolver) {
    this._install("onerror", core, userResolver)
    this._install("onunhandledrejection", core, userResolver)
  }

  _install(key: GlobalErrorHandlerKey, core: HackleCore, userResolver: HackleUserResolver) {
    if (key in this._installed) {
      return
    }
    const install = this._installations[key]
    install(core, userResolver)
    this._installed.push(key)
  }
}

let _oldOnErrorEventHandler: OnErrorEventHandler = null

function _installOnError(core: HackleCore, userResolver: HackleUserResolver) {
  _oldOnErrorEventHandler = window.onerror
  window.onerror = function (msg: any, url: any, line: any, column: any, error: any) {
    if (ObjectUtil.isNotNullOrUndefined(error)) {
      core.trackException(error, userResolver.resolveCurrentUser())
    }

    if (_oldOnErrorEventHandler) {
      // @ts-ignore
      _oldOnErrorEventHandler.apply(this, arguments)
    }
  }
}

let _oldOnUnhandledRejectionEventHandler: ((this: WindowEventHandlers, ev: PromiseRejectionEvent) => any) | null

function _installOnUnhandledRejection(core: HackleCore, userResolver: HackleUserResolver) {
  _oldOnUnhandledRejectionEventHandler = window.onunhandledrejection
  window.onunhandledrejection = function (e: any) {
    let error
    try {
      if ("reason" in e) {
        error = e.reason
      } else if ("detail" in e && "reason" in e.detail) {
        error = e.detail.reason
      }
    } catch (_) {
      // ignore
    }

    if (error instanceof Error) {
      core.trackException(error, userResolver.resolveCurrentUser())
    }

    if (_oldOnUnhandledRejectionEventHandler) {
      // @ts-ignore
      _oldOnUnhandledRejectionEventHandler.apply(this, arguments)
    }
  }
}
