// import {VariationKey} from "../VariationKey"

import Logger from "../logger"
import ObjectUtil from "../util/ObjectUtil"
import IdentifierUtil from "../util/IdentifierUtil"
import PropertyUtil from "../util/PropertyUtil"
import {
  InAppMessageDto,
  InAppMessageEventTriggerRuleDto,
  InAppMessageTargetContextDto,
  InAppMessageContextMessageDto,
  InAppMessageLang,
  InAppMessageContextDto,
  InAppMessageTargetRuleOverrideDto,
  InAppMessageStatus,
  InAppMessageTimeUnit,
  PlatformTypes,
  Exposure
} from "../workspace/iam"
import {
  BucketDto,
  ContainerDto,
  ContainerGroupDto,
  ExperimentDto,
  ParameterConfigurationDto,
  RemoteConfigParameterDto,
  RemoteConfigParameterValueDto,
  RemoteConfigTargetRuleDto,
  SegmentDto,
  TargetActionDto,
  TargetConditionDto,
  TargetDto,
  TargetKeyDto,
  TargetMatchDto,
  TargetRuleDto
} from "../workspace/dto"
import CollectionUtil from "../util/CollectionUtil"
import { PropertyOperation } from "../../../hackle/property/PropertyOperation"

export type Int = number
export type Long = number
export type List<T> = T[]
export type Double = number
export type UserId = string
export type VariationId = Long
export type VariationKey = string
export type ExperimentId = Long
export type ExperimentKey = Long
export type BucketId = Long
export type EventId = Long
export type EventKey = string
export type SegmentKey = string
export type ContainerId = Long
export type ParameterConfigurationId = Long
export type RemoteConfigParameterKey = string

const log = Logger.log

function parseOrNull<T extends string>(types: readonly T[], type: string): T | undefined {
  const t = types.find((it) => it === type)
  if (!t) {
    log.debug(`Unsupported type [${type}]. Please use the latest version of sdk.`)
  }
  return t
}

/**
 * An object that contains the decided config parameter value and the reason for the config parameter decision.
 */
export class RemoteConfigDecision {
  value: string | number | boolean
  reason: DecisionReason

  constructor(value: string | number | boolean, reason: DecisionReason) {
    this.value = value
    this.reason = reason
  }

  static of(value: string | number | boolean, reason: DecisionReason): RemoteConfigDecision {
    return new RemoteConfigDecision(value, reason)
  }
}

export class InAppMessageDecision {
  constructor(public readonly value: IAM | undefined, public readonly reason: DecisionReason) {}
}

/**
 * An object that contains the decided variation and the reason for the decision.
 */
export class Decision implements ParameterConfig {
  variation: VariationKey
  reason: DecisionReason
  config: ParameterConfig

  constructor(variation: VariationKey, reason: DecisionReason, config: ParameterConfig) {
    this.variation = variation
    this.reason = reason
    this.config = config
  }

  static of(variation: VariationKey, reason: DecisionReason, config: ParameterConfig = new EmptyParameterConfig()) {
    return new Decision(variation, reason, config)
  }

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    switch (typeof defaultValue) {
      case "string":
        return this.config.get(key, defaultValue)
      case "number":
        return this.config.get(key, defaultValue)
      case "boolean":
        return this.config.get(key, defaultValue)
      default:
        return this.config.get(key, defaultValue)
    }
  }
}

/**
 * An object that contains the decided flag and the reason for the feature flag decision.
 */
export class FeatureFlagDecision implements ParameterConfig {
  isOn: boolean
  reason: DecisionReason
  config: ParameterConfig

  constructor(isOn: boolean, reason: DecisionReason, config: ParameterConfig) {
    this.isOn = isOn
    this.reason = reason
    this.config = config
  }

  static on(reason: DecisionReason, config: ParameterConfig = new EmptyParameterConfig()): FeatureFlagDecision {
    return new FeatureFlagDecision(true, reason, config)
  }

  static off(reason: DecisionReason, config: ParameterConfig = new EmptyParameterConfig()): FeatureFlagDecision {
    return new FeatureFlagDecision(false, reason, config)
  }

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    switch (typeof defaultValue) {
      case "string":
        return this.config.get(key, defaultValue)
      case "number":
        return this.config.get(key, defaultValue)
      case "boolean":
        return this.config.get(key, defaultValue)
      default:
        return this.config.get(key, defaultValue)
    }
  }
}

export interface Config {
  get(key: string, defaultValue: string): string

  get(key: string, defaultValue: number): number

  get(key: string, defaultValue: boolean): boolean
}

/**
 * An interface model for remote Config
 */
export interface HackleRemoteConfig extends Config {}

export class EmptyHackleRemoteConfig implements HackleRemoteConfig {
  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    return defaultValue
  }
}

/**
 * An interface model for Parameter Config
 */
export interface ParameterConfig extends Config {}

export class EmptyParameterConfig implements ParameterConfig {
  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    return defaultValue
  }
}

/**
 * Describes the reason for the [Variation] decision.
 */
export class DecisionReason {
  /**
   * Indicates that the sdk is not ready to use. e.g. invalid SDK key.
   */
  static SDK_NOT_READY = "SDK_NOT_READY"

  /**
   * Indicates that the variation could not be decided due to an unexpected exception.
   */
  static EXCEPTION = "EXCEPTION"

  /**
   * Indicates that the input value is invalid.
   */
  static INVALID_INPUT = "INVALID_INPUT"

  /**
   * Indicates that no experiment was found for the experiment key provided by the caller.
   */
  static EXPERIMENT_NOT_FOUND = "EXPERIMENT_NOT_FOUND"

  /**
   * Indicates that the experiment is in draft.
   */
  static EXPERIMENT_DRAFT = "EXPERIMENT_DRAFT"

  /**
   * Indicates that the experiment was paused.
   */
  static EXPERIMENT_PAUSED = "EXPERIMENT_PAUSED"

  /**
   * Indicates that the experiment was completed.
   */
  static EXPERIMENT_COMPLETED = "EXPERIMENT_COMPLETED"

  /**
   * Indicates that the user has been overridden as a specific variation.
   */
  static OVERRIDDEN = "OVERRIDDEN"

  /**
   * Indicates that the experiment is running but the user is not allocated to the experiment.
   */
  static TRAFFIC_NOT_ALLOCATED = "TRAFFIC_NOT_ALLOCATED"

  /**
   * Indicates that the experiment is running but the user is not allocated to the mutual exclusion experiment.
   */
  static NOT_IN_MUTUAL_EXCLUSION_EXPERIMENT = "NOT_IN_MUTUAL_EXCLUSION_EXPERIMENT"

  /**
   * Indicates that no found identifier of experiment for the user provided by the caller.
   */
  static IDENTIFIER_NOT_FOUND = "IDENTIFIER_NOT_FOUND"

  /**
   * Indicates that the original decided variation has been dropped.
   */
  static VARIATION_DROPPED = "VARIATION_DROPPED"

  /**
   * Indicates that the user has been allocated to the experiment.
   */
  static TRAFFIC_ALLOCATED = "TRAFFIC_ALLOCATED"

  /**
   * Indicates that traffic was allocated by targeting from another experiment.
   */
  static TRAFFIC_ALLOCATED_BY_TARGETING = "TRAFFIC_ALLOCATED_BY_TARGETING"

  /**
   * Indicates that the user is not the target of the experiment.
   */
  static NOT_IN_EXPERIMENT_TARGET = "NOT_IN_EXPERIMENT_TARGET"

  /**
   * Indicates that no feature flag was found for the feature key provided by the caller.
   */
  static FEATURE_FLAG_NOT_FOUND = "FEATURE_FLAG_NOT_FOUND"

  /**
   * Indicates that the feature flag is inactive.
   */
  static FEATURE_FLAG_INACTIVE = "FEATURE_FLAG_INACTIVE"

  /**
   * Indicates that the user is matched to the individual target of the feature flag.
   */
  static INDIVIDUAL_TARGET_MATCH = "INDIVIDUAL_TARGET_MATCH"

  /**
   * Indicates that the user is matched to the target rule of the feature flag.
   */
  static TARGET_RULE_MATCH = "TARGET_RULE_MATCH"

  /**
   * Indicates that the user did not match any individual targets or target rules.
   */
  static DEFAULT_RULE = "DEFAULT_RULE"

  /**
   * Indicates that no remote config parameter was found for the parameter key provided by the caller.
   */
  static REMOTE_CONFIG_PARAMETER_NOT_FOUND = "REMOTE_CONFIG_PARAMETER_NOT_FOUND"

  /**
   * Indicates that no in app message was found for the parameter key provided by the caller.
   */
  static IN_APP_MESSAGE_NOT_FOUND = "IN_APP_MESSAGE_NOT_FOUND"

  /**
   * Indicates a mismatch between result type and request type.
   */
  static TYPE_MISMATCH = "TYPE_MISMATCH"
}

export type ExperimentType = "AB_TEST" | "FEATURE_FLAG"
export type ExperimentStatus = "DRAFT" | "RUNNING" | "PAUSED" | "COMPLETED"

export class Experiment {
  public static fromJSON(type: ExperimentType, dto: ExperimentDto): Experiment | undefined {
    const experimentStatus = Experiment.experimentStatusOrNull(dto.execution.status)
    const variations = dto.variations.map(
      (it) => new Variation(it.id, it.key, it.status === "DROPPED", it.parameterConfigurationId)
    )
    const userOverrides = CollectionUtil.associate(dto.execution.userOverrides, (it) => [it.userId, it.variationId])
    const segmentOverrides = CollectionUtil.mapNotNullOrUndefined(dto.execution.segmentOverrides, (it) =>
      TargetRule.fromJSON(it, TargetingType.IDENTIFIER)
    )
    const targetAudiences = CollectionUtil.mapNotNullOrUndefined(dto.execution.targetAudiences, (it) =>
      Target.fromJSON(it, TargetingType.PROPERTY)
    )
    const targetRules = CollectionUtil.mapNotNullOrUndefined(dto.execution.targetRules, (it) =>
      TargetRule.fromJSON(it, TargetingType.PROPERTY)
    )
    const defaultRule = TargetAction.fromJSON(dto.execution.defaultRule)
    return (
      experimentStatus &&
      defaultRule &&
      new Experiment(
        dto.id,
        dto.key,
        type,
        dto.identifierType,
        experimentStatus,
        dto.version,
        variations,
        userOverrides,
        segmentOverrides,
        targetAudiences,
        targetRules,
        defaultRule,
        dto.containerId,
        dto.winnerVariationId
      )
    )
  }

  private static experimentStatusOrNull(executionStatus: string): ExperimentStatus | undefined {
    switch (executionStatus) {
      case "READY":
        return "DRAFT"
      case "RUNNING":
        return "RUNNING"
      case "PAUSED":
        return "PAUSED"
      case "STOPPED":
        return "COMPLETED"
      default:
        log.debug(`Unsupported status [${executionStatus}]`)
        return undefined
    }
  }

  id: ExperimentId
  key: ExperimentKey
  type: ExperimentType
  identifierType: string
  status: ExperimentStatus
  version: Long
  variations: Variation[]
  userOverrides: Map<UserId, VariationId>
  segmentOverrides: TargetRule[]
  targetAudiences: Target[]
  targetRules: TargetRule[]
  defaultRule: TargetAction
  containerId: Long | undefined
  _winnerVariationId: VariationId | undefined

  constructor(
    id: ExperimentId,
    key: ExperimentKey,
    type: ExperimentType,
    identifierType: string,
    status: ExperimentStatus,
    version: Long,
    variations: Variation[],
    userOverrides: Map<UserId, VariationId>,
    segmentOverrides: TargetRule[],
    targetAudiences: Target[],
    targetRules: TargetRule[],
    defaultRule: TargetAction,
    containerId: Long | undefined,
    winnerVariationId: VariationId | undefined
  ) {
    this.id = id
    this.key = key
    this.type = type
    this.identifierType = identifierType
    this.status = status
    this.version = version
    this.variations = variations
    this.userOverrides = userOverrides
    this.segmentOverrides = segmentOverrides
    this.targetAudiences = targetAudiences
    this.targetRules = targetRules
    this.defaultRule = defaultRule
    this.containerId = containerId
    this._winnerVariationId = winnerVariationId
  }

  _winnerVariationOrNull(): Variation | undefined {
    if (this._winnerVariationId) {
      return this._getVariationByIdOrNull(this._winnerVariationId)
    }

    return undefined
  }

  _getVariationByIdOrNull(variationId: VariationId): Variation | undefined {
    return this.variations.find((it) => it.id === variationId)
  }

  _getVariationByKeyOrNull(variationKey: VariationKey): Variation | undefined {
    return this.variations.find((it) => it.key === variationKey)
  }
}

export class Variation {
  id: VariationId
  key: VariationKey
  isDropped: boolean
  parameterConfigurationId: Long | undefined

  constructor(id: VariationId, key: VariationKey, isDropped: boolean, parameterConfigurationId: Long | undefined) {
    this.id = id
    this.key = key
    this.isDropped = isDropped
    this.parameterConfigurationId = parameterConfigurationId
  }
}

export class Bucket {
  public static fromJSON(dto: BucketDto): Bucket {
    return new Bucket(
      dto.seed,
      dto.slotSize,
      dto.slots.map(
        ({ startInclusive, endExclusive, variationId }) => new Slot(startInclusive, endExclusive, variationId)
      )
    )
  }

  seed: number
  slotSize: number
  slots: Slot[]

  constructor(seed: number, slotSize: number, slots: Slot[]) {
    this.seed = seed
    this.slotSize = slotSize
    this.slots = slots
  }
}

export class Slot {
  startInclusive: number
  endExclusive: number
  variationId: VariationId

  constructor(startInclusive: number, endExclusive: number, variationId: VariationId) {
    this.startInclusive = startInclusive
    this.endExclusive = endExclusive
    this.variationId = variationId
  }

  contains(slotNumber: number): boolean {
    return this.startInclusive <= slotNumber && slotNumber < this.endExclusive
  }
}

export class EventType {
  id: EventId
  key: EventKey

  constructor(id: EventId, key: EventKey) {
    this.id = id
    this.key = key
  }
}

export interface HackleEvent<T = string> {
  key: string
  value?: number
  properties?: Properties<T>
}

export interface HackleUser {
  identifiers: Identifiers
  properties: Properties
  hackleProperties: Properties
}

export class IdentifierType {
  static ID = "$id"
  static USER = "$userId"
  static DEVICE = "$deviceId"
  static SESSION = "$sessionId"
  static HACKLE_DEVICE = "$hackleDeviceId"
}

export interface User {
  id?: string
  userId?: string
  deviceId?: string
  identifiers?: Identifiers
  properties?: Properties
}

export interface Identifiers {
  [key: string]: string
}

export class IdentifiersBuilder {
  identifiers: Identifiers = {}

  addIdentifiers(identifiers: Identifiers): IdentifiersBuilder {
    for (const identifierType in identifiers) {
      this.add(identifierType, identifiers[identifierType])
    }
    return this
  }

  add(type: string, value: any): IdentifiersBuilder {
    const sanitizeIdentifierValue = IdentifierUtil.sanitizeValue(value)
    if (IdentifierUtil.isValidType(type) && sanitizeIdentifierValue) {
      this.identifiers[type] = value
    } else {
      log.warn(`Invalid user identifier [type=${type}, value=${value}]`)
    }
    return this
  }

  build(): Identifiers {
    return this.identifiers
  }
}

export type PropertyValue = string | boolean | number | Array<string | number> | null | undefined

const isValidUser = (user: User): boolean => {
  return Boolean(
    user.id || user.userId || user.deviceId || (user.identifiers && Object.keys(user.identifiers).length > 0)
  )
}

export const sanitizeUser = (user: any): User | null => {
  const sanitized: User = {}

  sanitized.id = IdentifierUtil.sanitizeValue(user.id) ?? undefined

  sanitized.userId = IdentifierUtil.sanitizeValue(user.userId) ?? undefined

  sanitized.deviceId = IdentifierUtil.sanitizeValue(user.deviceId) ?? undefined

  if (typeof user.properties === "object") {
    sanitized.properties = PropertyUtil.sanitize(user.properties)
  }

  if (typeof user.identifiers === "object") {
    sanitized.identifiers = IdentifierUtil.sanitize(user.identifiers)
  }

  if (isValidUser(sanitized)) {
    return sanitized
  } else {
    return null
  }
}

export interface Properties<T = string | PropertyOperation> {
  [key: string | PropertyOperation]: T extends PropertyOperation ? Properties<string> : PropertyValue
}

export class Target {
  public static fromJSON(dto: TargetDto, targetingType: TargetingType): Target | undefined {
    const conditions = CollectionUtil.mapNotNullOrUndefined(dto.conditions, (it) =>
      TargetCondition.fromJSON(it, targetingType)
    )
    return new Target(conditions)
  }

  conditions: TargetCondition[]

  constructor(conditions: TargetCondition[]) {
    this.conditions = conditions
  }

  public toJSON(): TargetDto {
    return {
      conditions: this.conditions.map((condition) => condition.toJSON())
    }
  }
}

export class TargetCondition {
  public static fromJSON(dto: TargetConditionDto, targetingType: TargetingType): TargetCondition | undefined {
    const key = TargetKey.fromJSON(dto.key)
    if (!key) {
      return undefined
    }
    if (!targetingType.supports(key.type)) {
      return undefined
    }
    const match = TargetMatch.fromJSON(dto.match)
    return match && new TargetCondition(key, match)
  }

  key: TargetKey
  match: TargetMatch

  constructor(key: TargetKey, match: TargetMatch) {
    this.key = key
    this.match = match
  }

  public toJSON(): TargetConditionDto {
    return {
      key: this.key.toJSON(),
      match: this.match.toJSON()
    }
  }
}

export class TargetKey {
  public static fromJSON(dto: TargetKeyDto): TargetKey | undefined {
    const keyType = parseOrNull(TARGET_KEY_TYPES, dto.type)
    return keyType && new TargetKey(keyType, dto.name)
  }

  type: TargetKeyType
  name: string

  constructor(type: TargetKeyType, name: string) {
    this.type = type
    this.name = name
  }

  public toJSON(): TargetKeyDto {
    return {
      type: this.type,
      name: this.name
    }
  }
}

export class TargetMatch {
  public static fromJSON(dto: TargetMatchDto): TargetMatch | undefined {
    const matchType = parseOrNull(MATCH_TYPES, dto.type)
    const operator = parseOrNull(MATCH_OPERATORS, dto.operator)
    const valueType = parseOrNull(MATCH_VALUE_TYPES, dto.valueType)
    return matchType && operator && valueType && new TargetMatch(matchType, operator, valueType, dto.values)
  }

  type: MatchType
  operator: MatchOperator
  valueType: MatchValueType
  values: any[]

  constructor(type: MatchType, operator: MatchOperator, valueType: MatchValueType, values: any[]) {
    this.type = type
    this.operator = operator
    this.valueType = valueType
    this.values = values
  }

  public toJSON(): TargetMatchDto {
    return {
      operator: this.operator,
      type: this.type,
      valueType: this.valueType,
      values: this.values
    }
  }
}

export class TargetAction {
  public static fromJSON(dto: TargetActionDto): TargetAction | undefined {
    const type = parseOrNull(TARGET_ACTION_TYPES, dto.type)
    return type && new TargetAction(type, dto.variationId, dto.bucketId)
  }

  type: TargetActionType
  variationId: number | undefined
  bucketId: number | undefined

  constructor(type: TargetActionType, variationId: number | undefined, bucketId: number | undefined) {
    this.type = type
    this.variationId = variationId
    this.bucketId = bucketId
  }
}

export class TargetRule {
  public static fromJSON(dto: TargetRuleDto, targetingType: TargetingType): TargetRule | undefined {
    const target = Target.fromJSON(dto.target, targetingType)
    const action = TargetAction.fromJSON(dto.action)
    return target && action && new TargetRule(target, action)
  }

  target: Target
  action: TargetAction

  constructor(target: Target, action: TargetAction) {
    this.target = target
    this.action = action
  }
}

export class Segment {
  public static fromJSON(dto: SegmentDto): Segment | undefined {
    const segmentType = parseOrNull(SEGMENT_TYPES, dto.type)
    return (
      segmentType &&
      new Segment(
        dto.id,
        dto.key,
        segmentType,
        CollectionUtil.mapNotNullOrUndefined(dto.targets, (it) => Target.fromJSON(it, TargetingType.SEGMENT))
      )
    )
  }

  id: Long
  key: SegmentKey
  type: SegmentType
  targets: Target[]

  constructor(id: Long, key: SegmentKey, type: SegmentType, targets: Target[]) {
    this.id = id
    this.key = key
    this.type = type
    this.targets = targets
  }
}

export class Container {
  public static fromJSON(dto: ContainerDto): Container {
    return new Container(
      dto.id,
      dto.bucketId,
      dto.groups.map((it) => ContainerGroup.fromJSON(it))
    )
  }

  id: Long
  bucketId: Long
  groups: ContainerGroup[]

  constructor(id: Long, bucketId: Long, groups: ContainerGroup[]) {
    this.id = id
    this.bucketId = bucketId
    this.groups = groups
  }

  getGroupOrNull(containerGroupId: Long): ContainerGroup | undefined {
    return this.groups.find((it) => it.id === containerGroupId)
  }
}

export class ContainerGroup {
  public static fromJSON(dto: ContainerGroupDto): ContainerGroup {
    return new ContainerGroup(dto.id, dto.experiments)
  }

  id: Long
  experiments: Long[]

  constructor(id: Long, experiments: Long[]) {
    this.id = id
    this.experiments = experiments
  }
}

export class ParameterConfiguration implements ParameterConfig {
  public static fromJSON(dto: ParameterConfigurationDto): ParameterConfiguration {
    return new ParameterConfiguration(
      dto.id,
      CollectionUtil.associate(dto.parameters, (it) => [it.key, it.value])
    )
  }

  id: Long
  parameters: Map<string, any>

  constructor(id: Long, parameters: Map<string, any>) {
    this.id = id
    this.parameters = parameters
  }

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    const parameterValue = this.parameters.get(key)

    if (ObjectUtil.isNullOrUndefined(parameterValue)) {
      return defaultValue
    }

    if (ObjectUtil.isNullOrUndefined(defaultValue)) {
      return parameterValue
    }

    if (typeof parameterValue === typeof defaultValue) {
      return parameterValue
    }

    return defaultValue
  }
}

export class RemoteConfigParameter {
  public static fromJSON(dto: RemoteConfigParameterDto): RemoteConfigParameter | undefined {
    const remoteConfigParameterType = parseOrNull(MATCH_VALUE_TYPES, dto.type)
    const targetRules = CollectionUtil.mapNotNullOrUndefined(dto.targetRules, (it) =>
      RemoteConfigTargetRule.fromJSON(it, TargetingType.PROPERTY)
    )
    const defaultValue = RemoteConfigParameterValue.fromJSON(dto.defaultValue)
    return (
      remoteConfigParameterType &&
      new RemoteConfigParameter(
        dto.id,
        dto.key,
        remoteConfigParameterType,
        dto.identifierType,
        targetRules,
        defaultValue
      )
    )
  }

  id: Long
  key: string
  type: MatchValueType
  identifierType: string
  targetRules: RemoteConfigTargetRule[]
  defaultValue: RemoteConfigParameterValue

  constructor(
    id: Long,
    key: string,
    type: MatchValueType,
    identifierType: string,
    targetRules: RemoteConfigTargetRule[],
    defaultValue: RemoteConfigParameterValue
  ) {
    this.id = id
    this.key = key
    this.type = type
    this.identifierType = identifierType
    this.targetRules = targetRules
    this.defaultValue = defaultValue
  }
}

export class RemoteConfigTargetRule {
  public static fromJSON(
    dto: RemoteConfigTargetRuleDto,
    targetingType: TargetingType
  ): RemoteConfigTargetRule | undefined {
    const target = Target.fromJSON(dto.target, targetingType)
    return (
      target &&
      new RemoteConfigTargetRule(
        dto.key,
        dto.name,
        target,
        dto.bucketId,
        RemoteConfigParameterValue.fromJSON(dto.value)
      )
    )
  }

  key: string
  name: string
  target: Target
  bucketId: Long
  value: RemoteConfigParameterValue

  constructor(key: string, name: string, target: Target, bucketId: Long, value: RemoteConfigParameterValue) {
    this.key = key
    this.name = name
    this.target = target
    this.bucketId = bucketId
    this.value = value
  }
}

export class RemoteConfigParameterValue {
  public static fromJSON(dto: RemoteConfigParameterValueDto): RemoteConfigParameterValue {
    return new RemoteConfigParameterValue(dto.id, dto.value)
  }

  id: Long
  rawValue: any

  constructor(id: Long, rawValue: any) {
    this.id = id
    this.rawValue = rawValue
  }
}

function compareValues(a: number, b: number): number {
  return a - b
}

export class Version {
  readonly coreVersion: CoreVersion
  readonly prerelease: MetaVersion
  readonly build: MetaVersion

  constructor(coreVersion: CoreVersion, prerelease: MetaVersion, build: MetaVersion) {
    this.coreVersion = coreVersion
    this.prerelease = prerelease
    this.build = build
  }

  static regExp =
    /^(0|[1-9]\d*)(?:\.(0|[1-9]\d*))?(?:\.(0|[1-9]\d*))?(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/

  static tryParse(value: any): Version | undefined {
    const match = Version.regExp.exec(value)
    if (!match) return undefined

    const [_, major, minor = "0", patch = "0", prerelease, build] = match

    const coreVersion = new CoreVersion(parseInt(major, 10), parseInt(minor, 10), parseInt(patch, 10))

    return new Version(coreVersion, MetaVersion.parse(prerelease), MetaVersion.parse(build))
  }

  compareTo(other: Version): number {
    return this.coreVersion.compareTo(other.coreVersion) || this.prerelease.compareTo(other.prerelease)
  }

  isEqualTo(other: Version): boolean {
    return this.compareTo(other) === 0
  }

  isGreaterThan(other: Version): boolean {
    return this.compareTo(other) > 0
  }

  isGreaterThanOrEqualTo(other: Version): boolean {
    return this.compareTo(other) >= 0
  }

  isLessThan(other: Version): boolean {
    return this.compareTo(other) < 0
  }

  isLessThanOrEqualTo(other: Version): boolean {
    return this.compareTo(other) <= 0
  }
}

export class CoreVersion {
  readonly major: number
  readonly minor: number
  readonly patch: number

  constructor(major: number, minor: number, patch: number) {
    this.major = major
    this.minor = minor
    this.patch = patch
  }

  compareTo(other: CoreVersion): number {
    return (
      compareValues(this.major, other.major) ||
      compareValues(this.minor, other.minor) ||
      compareValues(this.patch, other.patch)
    )
  }
}

export class MetaVersion {
  readonly identifiers: string[]

  constructor(identifiers: string[]) {
    this.identifiers = identifiers
  }

  private static EMPTY = new MetaVersion([])

  static parse(text: string | undefined): MetaVersion {
    if (!text) {
      return MetaVersion.EMPTY
    } else {
      return new MetaVersion(text.split("."))
    }
  }

  isEmpty(): boolean {
    return this.identifiers.length === 0
  }

  isNotEmpty(): boolean {
    return !this.isEmpty()
  }

  compareTo(other: MetaVersion): number {
    if (this.isEmpty() && other.isEmpty()) {
      return 0
    }

    if (this.isEmpty() && other.isNotEmpty()) {
      return 1
    }

    if (this.isNotEmpty() && other.isEmpty()) {
      return -1
    }

    return this.compareIdentifiers(other)
  }

  private compareIdentifiers(other: MetaVersion): number {
    const length = Math.min(this.identifiers.length, other.identifiers.length)
    for (let i = 0; i < length; i++) {
      const result = MetaVersion.compareIdentifiers(this.identifiers[i], other.identifiers[i])
      if (result !== 0) {
        return result
      }
    }
    return compareValues(this.identifiers.length, other.identifiers.length)
  }

  private static numericIdentifierRegExp = /^(0|[1-9]\d*)$/

  private static compareIdentifiers(identifier1: string, identifier2: string): number {
    if (
      MetaVersion.numericIdentifierRegExp.test(identifier1) &&
      MetaVersion.numericIdentifierRegExp.test(identifier2)
    ) {
      return compareValues(+identifier1, +identifier2)
    }

    if (identifier1 === identifier2) {
      return 0
    }

    return identifier1 < identifier2 ? -1 : 1
  }
}

export type HackleValue = string | number | boolean

export const MATCH_TYPES = ["MATCH", "NOT_MATCH"] as const
export const MATCH_VALUE_TYPES = ["STRING", "NUMBER", "BOOLEAN", "VERSION", "JSON", "NULL", "UNKNOWN"] as const
export const MATCH_OPERATORS = ["IN", "CONTAINS", "STARTS_WITH", "ENDS_WITH", "GT", "GTE", "LT", "LTE"] as const
export const TARGET_ACTION_TYPES = ["VARIATION", "BUCKET"] as const
export const TARGET_KEY_TYPES = [
  "USER_ID",
  "USER_PROPERTY",
  "HACKLE_PROPERTY",
  "SEGMENT",
  "AB_TEST",
  "FEATURE_FLAG",
  "EVENT_PROPERTY"
] as const
export const SEGMENT_TYPES = ["USER_ID", "USER_PROPERTY"] as const

export type MatchType = (typeof MATCH_TYPES)[number]
export type MatchValueType = (typeof MATCH_VALUE_TYPES)[number]
export type MatchOperator = (typeof MATCH_OPERATORS)[number]
export type TargetActionType = (typeof TARGET_ACTION_TYPES)[number]
export type TargetKeyType = (typeof TARGET_KEY_TYPES)[number]
export type SegmentType = (typeof SEGMENT_TYPES)[number]

export class TargetingType {
  static IDENTIFIER = new TargetingType("SEGMENT")
  static PROPERTY = new TargetingType("SEGMENT", "USER_PROPERTY", "HACKLE_PROPERTY", "AB_TEST", "FEATURE_FLAG")
  static SEGMENT = new TargetingType("USER_ID", "USER_PROPERTY", "HACKLE_PROPERTY")
  static IAM = new TargetingType("EVENT_PROPERTY", "USER_PROPERTY", "HACKLE_PROPERTY")

  private supportedKeyTypes: TargetKeyType[]

  constructor(...supportedKeyTypes: TargetKeyType[]) {
    this.supportedKeyTypes = supportedKeyTypes
  }

  supports(keyType: TargetKeyType): boolean {
    return this.supportedKeyTypes.includes(keyType)
  }
}

export class IAMDateRange {
  public static fromJSON(dto: Pick<InAppMessageDto, "timeUnit" | "startEpochTime" | "endEpochTime">): IAMDateRange {
    return new IAMDateRange(
      dto.timeUnit,
      dto.startEpochTime === null ? dto.startEpochTime : dto.startEpochTime * 1000,
      dto.endEpochTime === null ? dto.endEpochTime : dto.endEpochTime * 1000
    )
  }

  constructor(
    private timeUnit: InAppMessageTimeUnit,
    private startDateTime: number | null,
    private endDateTime: number | null
  ) {}

  public within(timestamp: number) {
    if (this.timeUnit === "IMMEDIATE") {
      return true
    }

    if (this.startDateTime === null) {
      if (this.endDateTime !== null) {
        return timestamp <= this.endDateTime
      }

      return true
    }

    if (this.endDateTime === null) {
      return this.startDateTime <= timestamp
    }

    return this.startDateTime <= timestamp && timestamp <= this.endDateTime
  }

  public toJSON() {
    return {
      timeUnit: this.timeUnit,
      startEpochTime: this.startDateTime === null ? this.startDateTime : this.startDateTime / 1000,
      endEpochTime: this.endDateTime === null ? this.endDateTime : this.endDateTime / 1000
    }
  }
}

export class IAMEventTriggerRule {
  public static fromJSON(dto: InAppMessageEventTriggerRuleDto): IAMEventTriggerRule {
    return new IAMEventTriggerRule(
      dto.eventKey,
      CollectionUtil.mapNotNullOrUndefined(dto.targets, (target) => Target.fromJSON(target, TargetingType.IAM)),
      dto.priority
    )
  }

  constructor(
    public readonly eventKey: string,
    public readonly targets: List<Target>,
    public readonly priority: number = 0
  ) {}

  public toJSON(): InAppMessageEventTriggerRuleDto {
    return {
      eventKey: this.eventKey,
      targets: this.targets.map((target) => target.toJSON()),
      priority: this.priority
    }
  }
}

export class IAMTargetRuleOverride {
  public static fromJSON(dto: InAppMessageTargetRuleOverrideDto): IAMTargetRuleOverride {
    return new IAMTargetRuleOverride(dto.identifierType, dto.identifiers)
  }

  constructor(private identifierType: string, private identifiers: string[]) {}

  public toTarget() {
    return new Target([
      new TargetCondition(
        new TargetKey("USER_ID", this.identifierType),
        new TargetMatch("MATCH", "IN", "STRING", this.identifiers)
      )
    ])
  }

  public toJSON(): InAppMessageTargetRuleOverrideDto {
    return {
      identifierType: this.identifierType,
      identifiers: this.identifiers
    }
  }
}

export class IAMTargetContext {
  public static fromJSON(dto: InAppMessageTargetContextDto): IAMTargetContext {
    return new IAMTargetContext(
      CollectionUtil.mapNotNullOrUndefined(dto.targets, (target) => Target.fromJSON(target, TargetingType.PROPERTY)),
      CollectionUtil.mapNotNullOrUndefined(dto.overrides, (override) => IAMTargetRuleOverride.fromJSON(override))
    )
  }

  constructor(public readonly targets: List<Target>, public readonly overrides: List<IAMTargetRuleOverride>) {}

  public toJSON(): InAppMessageTargetContextDto {
    return {
      targets: this.targets.map((target) => target.toJSON()),
      overrides: this.overrides.map((override) => override.toJSON())
    }
  }
}

export class IAMContext {
  public static fromJSON(dto: InAppMessageContextDto): IAMContext {
    return new IAMContext(dto.defaultLang, dto.messages, dto.platformTypes, dto.exposure)
  }

  constructor(
    private defaultLang: InAppMessageLang,
    private messages: InAppMessageContextMessageDto[],
    private platformTypes: PlatformTypes,
    private exposure: Exposure
  ) {}

  public getDefaultMessage() {
    return this.messages.find((message) => message.lang === this.defaultLang) || this.messages[0]
  }

  public supportsWeb() {
    return this.platformTypes.includes("WEB")
  }

  public getMessageForLang(lang: string) {
    return this.messages.find((message) => message.lang === lang)
  }

  public toJSON(): InAppMessageContextDto {
    return {
      defaultLang: this.defaultLang,
      messages: this.messages,
      platformTypes: this.platformTypes,
      exposure: this.exposure
    }
  }
}

export class IAM {
  public static fromJSON(dto: InAppMessageDto): IAM {
    return new IAM(
      dto.key,
      dto.status,
      IAMDateRange.fromJSON(dto),
      dto.eventTriggerRules.map((rule) => IAMEventTriggerRule.fromJSON(rule)),
      IAMTargetContext.fromJSON(dto.targetContext),
      IAMContext.fromJSON(dto.messageContext)
    )
  }

  constructor(
    public readonly key: number,
    private status: InAppMessageStatus,
    public readonly dateRange: IAMDateRange,
    public readonly eventTriggerRules: IAMEventTriggerRule[],
    public readonly targetContext: IAMTargetContext,
    public readonly messageContext: IAMContext
  ) {}

  public isActive() {
    return this.status === "ACTIVE"
  }

  public supportsWeb() {
    return this.messageContext.supportsWeb()
  }

  public toJSON(): InAppMessageDto {
    return {
      key: this.key,
      status: this.status,
      ...this.dateRange.toJSON(),
      eventTriggerRules: this.eventTriggerRules.map((rule) => rule.toJSON()),
      targetContext: this.targetContext.toJSON(),
      messageContext: this.messageContext.toJSON()
    }
  }
}
