import { PropertyOperation } from "./PropertyOperation"
import {
  PropertyAppendOnceOperator,
  PropertyAppendOperator,
  PropertyClearAllOperator,
  PropertyIncrementOperator,
  PropertyOperator,
  PropertyPrependOnceOperator,
  PropertyPrependOperator,
  PropertyRemoveOperator,
  PropertySetOnceOperator,
  PropertySetOperator,
  PropertyUnsetOperator
} from "./PropertyOperator"

export class PropertyOperators {
  static get(operation: PropertyOperation): PropertyOperator {
    switch (operation) {
      case PropertyOperation.SET:
        return new PropertySetOperator()
      case PropertyOperation.SET_ONCE:
        return new PropertySetOnceOperator()
      case PropertyOperation.UNSET:
        return new PropertyUnsetOperator()
      case PropertyOperation.INCREMENT:
        return new PropertyIncrementOperator()
      case PropertyOperation.APPEND:
        return new PropertyAppendOperator()
      case PropertyOperation.APPEND_ONCE:
        return new PropertyAppendOnceOperator()
      case PropertyOperation.PREPEND:
        return new PropertyPrependOperator()
      case PropertyOperation.PREPEND_ONCE:
        return new PropertyPrependOnceOperator()
      case PropertyOperation.CLEAR_ALL:
        return new PropertyClearAllOperator()
      case PropertyOperation.REMOVE:
        return new PropertyRemoveOperator()
    }
  }
}
