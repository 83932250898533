import { Metric } from "../Metric"
import { MetricRegistry } from "../MetricRegistry"

export interface DelegatingMetric extends Metric {
  add(registry: MetricRegistry): void
}

export function isDelegatingMetric(metric: Metric): metric is DelegatingMetric {
  return typeof (metric as any).add !== "undefined" && typeof (metric as any).add === "function"
}
