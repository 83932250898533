import Logger from "../../logger"
import { MetricRegistry } from "../MetricRegistry"

export abstract class PushMetricRegistry extends MetricRegistry {
  private currentIntervalId: NodeJS.Timer | number | undefined = undefined

  constructor(private pushIntervalMillis: number) {
    super()
    this.pushIntervalMillis = pushIntervalMillis
  }

  abstract publish(): void

  start() {
    if (this.currentIntervalId !== undefined) return

    this.currentIntervalId = setInterval(() => {
      this.publish()
    }, this.pushIntervalMillis)
    Logger.log.debug(`PushMetricRegistry started. Publish metrics every ${this.pushIntervalMillis}ms.`)
  }

  stop() {
    if (this.currentIntervalId === undefined) return

    clearInterval(this.currentIntervalId)
    this.currentIntervalId = undefined
    this.publish()
    Logger.log.debug(`PushMetricRegistry stopped.`)
  }

  close(): void {
    this.stop()
  }
}
