import { TimeUnit, TimeUtil } from "../../util/TimeUtil"
import { MetricId, Measurement } from "../Metric"
import { Timer } from "../Timer"

export class CumulativeTimer implements Timer {
  public id: MetricId
  private _count = 0
  private _total = 0
  private _max = 0

  constructor(id: MetricId) {
    this.id = id
  }

  count(): number {
    return this._count
  }

  totalTime(unit: TimeUnit): number {
    return TimeUtil.nanosToUnit(this._total, unit)
  }

  max(unit: TimeUnit): number {
    return TimeUtil.nanosToUnit(this._max, unit)
  }

  mean(unit: TimeUnit): number {
    return this.count() === 0 ? 0.0 : this.totalTime(unit) / this.count()
  }

  record(amount: number, unit: TimeUnit): void {
    if (amount < 0) return

    const convertedTime = TimeUtil.converter[unit](amount, "nanoseconds")
    this._count = this.count() + 1
    this._total += convertedTime
    this._max = Math.max(convertedTime, this._max)
  }

  measure(): Measurement[] {
    return [
      new Measurement("count", this.count.bind(this)),
      new Measurement("total", () => this.totalTime("milliseconds")),
      new Measurement("max", () => this.max("milliseconds")),
      new Measurement("mean", () => this.mean("milliseconds"))
    ]
  }
}
