import { Counter } from "../Counter"
import { Measurement, MetricId } from "../Metric"

export class NoopCounter implements Counter {
  id: MetricId
  constructor(id: MetricId) {
    this.id = id
  }

  count() {
    return 0
  }

  increment(delta?: number): void {}

  measure(): Measurement[] {
    return [new Measurement("count", this.count.bind(this))]
  }
}

export default {}
