import { IAM } from "../../../model/model"
import { EvaluatorEvaluation } from "../Evaluator"

export class TrackInAppMessageEvaluation implements EvaluatorEvaluation {
  constructor(
    public readonly reason: string,
    public readonly targetEvaluations: EvaluatorEvaluation[],
    public readonly message: IAM | undefined
  ) {}
}
