import { Counter } from "../Counter"
import { Measurement, MetricId } from "../Metric"

export class CumulativeCounter implements Counter {
  public id: MetricId
  private counterValue = 0

  constructor(id: MetricId) {
    this.id = id
  }

  count(): number {
    return this.counterValue
  }

  increment(delta?: number): void {
    this.counterValue += delta ?? 1
  }

  measure(): Measurement[] {
    return [new Measurement("count", this.count.bind(this))]
  }
}
