import Logger from "../logger"
import { Counter } from "./Counter"
import { DelegatingMetricRegistry } from "./delegating/DelegatingMetricRegistry"
import { MetricRegistry } from "./MetricRegistry"
import { Timer } from "./Timer"

export class Metrics {
  static globalRegistry = new DelegatingMetricRegistry()

  static counter(name: string, tags: { [key: string]: string }): Counter {
    return Metrics.globalRegistry.counter(name, tags)
  }

  static timer(name: string, tags: { [key: string]: string }): Timer {
    return Metrics.globalRegistry.timer(name, tags)
  }

  static addRegistry(registry: MetricRegistry) {
    Metrics.globalRegistry.add(registry)
    Logger.log.debug(`MetricRegistry added ${registry.name}`)
  }
}
