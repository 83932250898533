export type PropertyOperationKeys = Exclude<keyof typeof PropertyOperation, "prototype">
export type PropertyOperationValues = (typeof PropertyOperation)[PropertyOperationKeys]

export enum PropertyOperation {
  SET = "$set",
  SET_ONCE = "$setOnce",
  UNSET = "$unset",
  INCREMENT = "$increment",
  APPEND = "$append",
  APPEND_ONCE = "$appendOnce",
  PREPEND = "$prepend",
  PREPEND_ONCE = "$prependOnce",
  REMOVE = "$remove",
  CLEAR_ALL = "$clearAll"
}
