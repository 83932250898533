import Evaluator, { EvaluatorContext, EvaluatorEvaluation, EvaluatorRequest } from "./Evaluator"

export default abstract class ContextualEvaluator<
  Request extends EvaluatorRequest,
  Evaluation extends EvaluatorEvaluation
> implements Evaluator
{
  abstract supports(request: EvaluatorRequest): request is Request

  protected abstract evaluateInternal(request: Request, context: EvaluatorContext): Evaluation

  evaluate(request: EvaluatorRequest, context: EvaluatorContext): Evaluation {
    if (!this.supports(request)) {
      throw new Error(`Unsupported EvaluatorRequest ${request.toString()}`)
    }
    if (context.contains(request)) {
      const stack = context.stack.map((it) => it.toString()).join(" - ")
      throw new Error(`Circular evaluation has occurred [${stack} - ${request.toString()}]`)
    }
    context.addRequest(request)
    try {
      return this.evaluateInternal(request, context)
    } finally {
      context.removeRequest(request)
    }
  }
}
