import EvaluationFlowFactory from "../../flow/EvaluationFlowFactory"
import ContextualEvaluator from "../ContextualEvaluator"
import ExperimentRequest from "./ExperimentRequest"
import ExperimentEvaluation from "./ExperimentEvaluation"
import { EvaluatorContext, EvaluatorRequest } from "../Evaluator"

export default class ExperimentEvaluator extends ContextualEvaluator<ExperimentRequest, ExperimentEvaluation> {

  private readonly evaluationFlowFactory: EvaluationFlowFactory

  constructor(evaluationFlowFactory: EvaluationFlowFactory) {
    super()
    this.evaluationFlowFactory = evaluationFlowFactory
  }

  supports(request: EvaluatorRequest): request is ExperimentRequest {
    return (request as ExperimentRequest).experiment !== undefined
  }

  protected evaluateInternal(request: ExperimentRequest, context: EvaluatorContext): ExperimentEvaluation {
    const evaluationFlow = this.evaluationFlowFactory.getFlow(request.experiment.type)
    return evaluationFlow.evaluate(request, context)
  }
}
