import { HackleEvent } from "../model/model"
import ObjectUtil from "../util/ObjectUtil"

export default class TraceTransformer {

  private static EVENT_KEY = "$trace"
  private static LEVEL_ERROR = "error"
  private static STACK_TRACK_LIMIT = 30

  static toEvent(exception: Error): HackleEvent {
    return {
      key: TraceTransformer.EVENT_KEY,
      properties: {
        level: TraceTransformer.LEVEL_ERROR,
        type: exception.name,
        message: exception.message ?? "",
        $stacktrace: TraceTransformer._stacktrace(exception)
      }
    }
  }

  static _stacktrace(exception: Error): string {
    if (ObjectUtil.isNullOrUndefined(exception.stack)) {
      return ""
    }
    return exception.stack.split("\n")
      .slice(0, TraceTransformer.STACK_TRACK_LIMIT)
      .join("\n")
  }
}
