import {
  InAppMessageTriggerManager,
  InAppMessageTriggerManagerCampaignEventPayload
} from "../../core/internal/iam/InAppMessageTriggerManager"
import { IAM } from "../../core/internal/model/model"
import { IStorage } from "../../core/internal/storage/Storage"
import { BrowserHackleClient } from "../index.browser"
import { HackleIAMRenderer, InAppMessageRendererMessageEvent } from "./InAppMessageRenderer"

export class InAppMessageRenderManager {
  private static HOUR_IN_MS = 60 * 60 * 1000
  // 24 hours in ms
  private static HIDE_EXPIRATION: number = 24 * InAppMessageRenderManager.HOUR_IN_MS
  public static RENDERER_SCRIPT = "https://static.hackle.io/sdk/iam-renderer/latest/iam-renderer.js"
  private static NAMESPACE = "hackle-iam"
  private static HACKLE_IAM_EVENT_TYPE = InAppMessageRenderManager.NAMESPACE
  private static CONTAINER_CLASS_NAME = InAppMessageRenderManager.NAMESPACE

  private static createRendererScript(src: string) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.src = src

    if ((process.env.NODE_ENV as string | undefined) === "test") {
      script.setAttribute("data-testid", "renderer-script")
    }

    return script
  }

  private static waitForLoad<T extends HTMLElement>(el: T) {
    return new Promise<T>((resolve, reject) => {
      const handleLoad = () => {
        cleanup()
        resolve(el)
      }

      const handleError = (ev: ErrorEvent) => {
        cleanup()
        reject(ev.message)
      }

      const cleanup = () => {
        el.removeEventListener("error", handleError)
        el.removeEventListener("load", handleLoad)
      }

      el.addEventListener("load", handleLoad)
      el.addEventListener("error", handleError)
    })
  }

  private static async waitForRendererScript(src: string) {
    if ((window as any).HackleIAMRenderer) {
      return
    }

    const script = InAppMessageRenderManager.createRendererScript(src)
    document.head.appendChild(script)
    return InAppMessageRenderManager.waitForLoad(script)
  }

  private static getBrowserLang() {
    const lang = (window.navigator.language || window.navigator.languages[0]).split("-")[0]
    return lang
  }

  private opening: boolean = false
  private container: HTMLElement | null = null
  private active: { message: IAM; renderer: HackleIAMRenderer } | null = null

  constructor(
    private triggerManager: InAppMessageTriggerManager,
    private storage: IStorage,
    private expirationStorageKey: string,
    private client: BrowserHackleClient,
    private options = { rendererScriptUrl: InAppMessageRenderManager.RENDERER_SCRIPT }
  ) {
    this.initialize()
  }

  private initialize() {
    this.addListeners()
  }

  public destroy() {
    this.removeListeners()
    this.destroyActive()
  }

  private addListeners() {
    this.triggerManager.on("campaign", this.handleTriggerMessage)
    window.addEventListener(InAppMessageRenderManager.HACKLE_IAM_EVENT_TYPE, this.handleIAMEvent)
  }

  private removeListeners() {
    this.triggerManager.off("campaign", this.handleTriggerMessage)
    window.removeEventListener(InAppMessageRenderManager.HACKLE_IAM_EVENT_TYPE, this.handleIAMEvent)
  }

  private addContainer() {
    if (!this.container) {
      const container = document.createElement("div")
      container.classList.add(InAppMessageRenderManager.CONTAINER_CLASS_NAME)
      this.container = container
    }

    if ((process.env.NODE_ENV as string | undefined) === "test") {
      this.container.setAttribute("data-testid", InAppMessageRenderManager.CONTAINER_CLASS_NAME)
    }

    if (!this.container.parentNode) {
      document.body.appendChild(this.container)
    }

    return this.container
  }

  private removeContainer() {
    if (this.container) {
      this.container.parentNode?.removeChild(this.container)
      this.container = null
    }
  }

  private destroyActive() {
    this.active?.renderer.destroy()
    this.active = null
    this.removeContainer()
  }

  private markMessageHidden(key: number | string, timestamp: number) {
    this.storage.setItem(`${this.expirationStorageKey}${key}`, `${timestamp}`)
  }

  private checkMessageHidden(message: IAM, timestamp: number) {
    const value = this.storage.getItem(`${this.expirationStorageKey}${message.key}`)

    if (value) {
      const expiration = Number.parseInt(value, 10)

      if (!Number.isNaN(expiration)) {
        return expiration >= timestamp
      }
    }

    return false
  }

  private handleIAMEvent = (ev: Event) => {
    if (ev instanceof CustomEvent && ev.type === InAppMessageRenderManager.HACKLE_IAM_EVENT_TYPE) {
      const detail: InAppMessageRendererMessageEvent = ev.detail
      this.client.track(detail)

      if (
        detail.key === "$in_app_close" ||
        detail.key === "$in_app_hidden" ||
        (detail.key === "$in_app_action" &&
          (detail.properties.action_type === "CLOSE" || detail.properties.action_type === "HIDDEN"))
      ) {
        if (
          detail.key === "$in_app_hidden" ||
          (detail.key === "$in_app_action" && detail.properties.action_type === "HIDDEN")
        ) {
          let expirationOffset = InAppMessageRenderManager.HIDE_EXPIRATION

          if (detail.key === "$in_app_action" && detail.properties.action_type === "HIDDEN") {
            const actionValue = detail.properties.action_value

            if (typeof actionValue === "number") {
              expirationOffset = actionValue * InAppMessageRenderManager.HOUR_IN_MS
            } else if (typeof actionValue === "string") {
              const parsedValue = Number.parseInt(actionValue, 10)

              if (!Number.isNaN(parsedValue)) {
                expirationOffset = parsedValue * InAppMessageRenderManager.HOUR_IN_MS
              }
            }
          }

          this.markMessageHidden(detail.properties.in_app_message_key, Date.now() + expirationOffset)
        }

        this.destroyActive()
      }
    }
  }

  private handleTriggerMessage = async ({ message, timestamp }: InAppMessageTriggerManagerCampaignEventPayload) => {
    if (this.active) return
    if (this.opening) return
    if (this.checkMessageHidden(message, timestamp)) return

    this.opening = true
    let renderer: HackleIAMRenderer | null = null

    try {
      await InAppMessageRenderManager.waitForRendererScript(this.options.rendererScriptUrl)

      const container = this.addContainer()
      container.style.display = "none"
      container.classList.add(`${InAppMessageRenderManager.CONTAINER_CLASS_NAME}--loading`)

      const browserLang = InAppMessageRenderManager.getBrowserLang()
      const contextMessage =
        message.messageContext.getMessageForLang(browserLang) || message.messageContext.getDefaultMessage()

      const scriptRenderer = (window as any).HackleIAMRenderer
      renderer = new scriptRenderer.HackleIAMRenderer(container) as HackleIAMRenderer
      await renderer.render({ message: message.toJSON(), contextMessage })
      this.active = { message, renderer }

      container.classList.remove(`${InAppMessageRenderManager.CONTAINER_CLASS_NAME}--loading`)
      container.style.display = "block"
    } catch (err) {
      renderer?.destroy()
      this.destroyActive()
    }

    this.opening = false
  }
}
