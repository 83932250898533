import EventProcessor from "./internal/event/EventProcessor"
import {
  Decision,
  DecisionReason,
  EmptyParameterConfig,
  EventType,
  Experiment,
  FeatureFlagDecision,
  HackleEvent,
  HackleUser,
  InAppMessageDecision,
  MatchValueType,
  ParameterConfig,
  RemoteConfigDecision,
  VariationKey
} from "./internal/model/model"
import Event, { Track } from "./internal/event/Event"
import Logger from "./internal/logger"
import WorkspaceFetcher from "./internal/workspace/WorkspaceFetcher"
import { DEFAULT_ON_READY_TIMEOUT } from "../config"
import ObjectUtil from "./internal/util/ObjectUtil"
import TraceTransformer from "./internal/trace/TraceTransformer"
import { Comparable } from "./internal/util/Comparable"
import ExperimentEvaluator from "./internal/evaluation/evalautor/experiment/ExperimentEvaluator"
import RemoteConfigEvaluator from "./internal/evaluation/evalautor/remoteconfig/RemoteConfigEvaluator"
import ExperimentEvaluation from "./internal/evaluation/evalautor/experiment/ExperimentEvaluation"
import ExperimentRequest from "./internal/evaluation/evalautor/experiment/ExperimentRequest"
import { EvaluatorContext } from "./internal/evaluation/evalautor/Evaluator"
import RemoteConfigRequest from "./internal/evaluation/evalautor/remoteconfig/RemoteConfigRequest"
import {
  DelegatingManualOverrideStorage,
  ManualOverrideStorage
} from "./internal/evaluation/target/ManualOverrideStorage"
import DelegatingEvaluator from "./internal/evaluation/evalautor/DelegatingEvaluator"
import EvaluationFlowFactory from "./internal/evaluation/flow/EvaluationFlowFactory"
import EventFactory from "./internal/event/EventFactory"
import { SystemClock } from "./internal/util/TimeUtil"
import { InAppMessageEvaluator } from "./internal/evaluation/evalautor/iam/InAppMessageEvaluator"
import { InAppMessageRequest } from "./internal/evaluation/evalautor/iam/InAppMessageRequest"
import { TrackInAppMessageEvaluator } from "./internal/evaluation/evalautor/iam/TrackInAppMessageEvaluator"
import { TrackInAppMessageRequest } from "./internal/evaluation/evalautor/iam/TrackInAppMessageRequest"

const log = Logger.log

/**
 * DO NOT use this class directly.
 * This class is only used internally by Hackle.
 * Backward compatibility is not supported.
 * Please use server-side or client-side HackleClient instead.
 */
export default class HackleCore {
  private readonly experimentEvaluator: ExperimentEvaluator
  private readonly remoteConfigEvaluator: RemoteConfigEvaluator
  public readonly inAppMessageEvaluator: InAppMessageEvaluator
  public readonly trackInAppMessageEvaluator: TrackInAppMessageEvaluator
  public readonly workspaceFetcher: WorkspaceFetcher
  private readonly eventFactory: EventFactory
  public readonly eventProcessor: EventProcessor
  private readonly errorDedupDeterminer: ErrorDedupDeterminer
  private readonly readyPromise: any

  constructor(
    experimentEvaluator: ExperimentEvaluator,
    remoteConfigEvaluator: RemoteConfigEvaluator,
    inAppMessageEvaluator: InAppMessageEvaluator,
    trackInAppMessageEvaluator: TrackInAppMessageEvaluator,
    workspaceFetcher: WorkspaceFetcher,
    eventFactory: EventFactory,
    eventProcessor: EventProcessor,
    errorDedupDeterminer: ErrorDedupDeterminer
  ) {
    this.experimentEvaluator = experimentEvaluator
    this.remoteConfigEvaluator = remoteConfigEvaluator
    this.trackInAppMessageEvaluator = trackInAppMessageEvaluator
    this.inAppMessageEvaluator = inAppMessageEvaluator
    this.workspaceFetcher = workspaceFetcher
    this.eventFactory = eventFactory
    this.eventProcessor = eventProcessor
    this.errorDedupDeterminer = errorDedupDeterminer
    this.workspaceFetcher.start()
    this.eventProcessor.start()
    this.readyPromise = this.workspaceFetcher.onReady().then(
      () => {
        return { success: true }
      },
      (error: any) => {
        return { success: false, error: error }
      }
    )
  }

  static create(
    workspaceFetcher: WorkspaceFetcher,
    eventProcessor: EventProcessor,
    manualOverrideStorages: ManualOverrideStorage[]
  ): HackleCore {
    const delegatingEvaluator = new DelegatingEvaluator()

    const manualOverrideStorage = new DelegatingManualOverrideStorage(manualOverrideStorages)
    const evaluationFlowFactory = new EvaluationFlowFactory(delegatingEvaluator, manualOverrideStorage)

    const experimentEvaluator = new ExperimentEvaluator(evaluationFlowFactory)
    const remoteConfigEvaluator = new RemoteConfigEvaluator(
      evaluationFlowFactory.remoteConfigParameterTargetRuleDeterminer
    )
    const trackInAppMessageEvaluator = new TrackInAppMessageEvaluator(evaluationFlowFactory.trackInAppMessageDeterminer)
    const inAppMessageEvaluator = new InAppMessageEvaluator(evaluationFlowFactory.inAppMessageDeterminer)

    delegatingEvaluator.add(experimentEvaluator)
    delegatingEvaluator.add(remoteConfigEvaluator)

    return new HackleCore(
      experimentEvaluator,
      remoteConfigEvaluator,
      inAppMessageEvaluator,
      trackInAppMessageEvaluator,
      workspaceFetcher,
      new EventFactory(SystemClock.instance),
      eventProcessor,
      new ErrorDedupDeterminer()
    )
  }

  experiment(experimentKey: number, user: HackleUser, defaultVariation: VariationKey): Decision {
    if (!experimentKey) {
      log.error("experimentKey must not be empty")
      return Decision.of(defaultVariation, DecisionReason.INVALID_INPUT)
    }

    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return Decision.of(defaultVariation, DecisionReason.SDK_NOT_READY)
    }

    const experiment = workspace.getExperimentOrNull(experimentKey)

    if (!experiment) {
      log.warn("Experiment does not exist.")
      return Decision.of(defaultVariation, DecisionReason.EXPERIMENT_NOT_FOUND)
    }

    const request = ExperimentRequest.of(workspace, user, experiment, defaultVariation)
    const [evaluation, decision] = this.experimentInternal(request)

    const events = this.eventFactory.create(request, evaluation)
    events.forEach((event) => this.eventProcessor.process(event))

    return decision
  }

  experiments(user: HackleUser): Comparable<Experiment, Decision> {
    const decisions = new Comparable<Experiment, Decision>((a, b) => a.id === b.id)
    const workspace = this.workspaceFetcher.get()
    if (!workspace) {
      return decisions
    }

    workspace.getExperiments().forEach((experiment) => {
      const request = ExperimentRequest.of(workspace, user, experiment, "A")
      const [_, decision] = this.experimentInternal(request)
      decisions.add(experiment, decision)
    })

    return decisions
  }

  private experimentInternal(request: ExperimentRequest): [ExperimentEvaluation, Decision] {
    const evaluation = this.experimentEvaluator.evaluate(request, EvaluatorContext.create())
    const config = evaluation.config ?? new EmptyParameterConfig()
    const decision = Decision.of(evaluation.variationKey, evaluation.reason, config)
    return [evaluation, decision]
  }

  featureFlag(featureKey: number, user: HackleUser): FeatureFlagDecision {
    if (!featureKey) {
      log.error("featureKey must not be empty")
      return FeatureFlagDecision.off(DecisionReason.INVALID_INPUT)
    }

    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return FeatureFlagDecision.off(DecisionReason.SDK_NOT_READY)
    }

    const featureFlag = workspace.getFeatureFlagOrNull(featureKey)

    if (!featureFlag) {
      log.warn("FeatureFlag does not exist.")
      return FeatureFlagDecision.off(DecisionReason.FEATURE_FLAG_NOT_FOUND)
    }

    const request = ExperimentRequest.of(workspace, user, featureFlag, "A")
    const [evaluation, decision] = this.featureFlagInternal(request)

    const events = this.eventFactory.create(request, evaluation)
    events.forEach((event) => this.eventProcessor.process(event))

    return decision
  }

  featureFlags(user: HackleUser): Comparable<Experiment, FeatureFlagDecision> {
    const decisions = new Comparable<Experiment, FeatureFlagDecision>((a, b) => a.id === b.id)
    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      return decisions
    }

    workspace.getFeatureFlags().forEach((featureFlag) => {
      const request = ExperimentRequest.of(workspace, user, featureFlag, "A")
      const [_, decision] = this.featureFlagInternal(request)
      decisions.add(featureFlag, decision)
    })

    return decisions
  }

  private featureFlagInternal(request: ExperimentRequest): [ExperimentEvaluation, FeatureFlagDecision] {
    const evaluation = this.experimentEvaluator.evaluate(request, EvaluatorContext.create())
    const config: ParameterConfig = evaluation.config ?? new EmptyParameterConfig()
    const decision =
      evaluation.variationKey === "A"
        ? FeatureFlagDecision.off(evaluation.reason, config)
        : FeatureFlagDecision.on(evaluation.reason, config)
    return [evaluation, decision]
  }

  public trackInAppMessage(track: Track) {
    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return new InAppMessageDecision(undefined, DecisionReason.SDK_NOT_READY)
    }

    const request = new TrackInAppMessageRequest(workspace, track)
    const triggeredMessage = this.trackInAppMessageEvaluator.evaluate(request, EvaluatorContext.create())
    return new InAppMessageDecision(triggeredMessage.message, triggeredMessage.reason)
  }

  public inAppMessage(messageKey: number, user: HackleUser, timestamp: number = Date.now()) {
    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return new InAppMessageDecision(undefined, DecisionReason.SDK_NOT_READY)
    }

    const message = workspace.getInAppMessageOrNull(messageKey)

    if (!message) {
      log.warn("In app message does not exist.")
      return new InAppMessageDecision(undefined, DecisionReason.IN_APP_MESSAGE_NOT_FOUND)
    }

    const request = InAppMessageRequest.of(workspace, message, user, timestamp)
    const evaluation = this.inAppMessageEvaluator.evaluate(request, EvaluatorContext.create())
    return new InAppMessageDecision(evaluation.message, evaluation.reason)
  }

  track(event: HackleEvent, user: HackleUser, timestamp: number = new Date().getTime()) {
    if (!event) {
      log.warn("event must not be null.")
      return
    }

    if (typeof event !== "object") {
      log.warn("Event must be event type.")
      return
    }

    if (typeof event === "object") {
      if (!event.key || typeof event.key !== "string") {
        log.warn("Event key must be not null. or event key must be string type.")
        return
      }
    }

    const eventType = this.workspaceFetcher.get()?.getEventTypeOrNull(event.key) || new EventType(0, event.key)
    this.eventProcessor.process(Event.track(eventType, event, user, timestamp))
  }

  trackException(exception: Error, user: HackleUser) {
    if (this.errorDedupDeterminer.isDedupTarget(exception)) {
      return
    }

    const event = TraceTransformer.toEvent(exception)

    this.flush()
    this.track(event, user)
    this.flush()
  }

  flush() {
    this.eventProcessor.flush()
  }

  remoteConfig(
    parameterKey: string,
    user: HackleUser,
    requiredType: MatchValueType,
    defaultValue: string | number | boolean
  ): RemoteConfigDecision {
    const workspace = this.workspaceFetcher.get()

    if (ObjectUtil.isNullOrUndefined(workspace)) {
      log.warn("SDK not ready.")
      return RemoteConfigDecision.of(defaultValue, DecisionReason.SDK_NOT_READY)
    }

    const remoteConfigParameter = workspace.getRemoteConfigParameterOrNull(parameterKey)

    if (ObjectUtil.isNullOrUndefined(remoteConfigParameter)) {
      log.warn("Remote config parameter does not exist.")
      return RemoteConfigDecision.of(defaultValue, DecisionReason.REMOTE_CONFIG_PARAMETER_NOT_FOUND)
    }

    const request = RemoteConfigRequest.of(workspace, user, remoteConfigParameter, requiredType, defaultValue)
    const evaluation = this.remoteConfigEvaluator.evaluate(request, EvaluatorContext.create())

    const events = this.eventFactory.create(request, evaluation)
    events.forEach((event) => this.eventProcessor.process(event))

    return RemoteConfigDecision.of(evaluation.value, evaluation.reason)
  }

  onReady(block: () => void, timeout: number = DEFAULT_ON_READY_TIMEOUT): void {
    this.onInitialized({ timeout: timeout }).then(() => block())
  }

  onInitialized({ timeout = DEFAULT_ON_READY_TIMEOUT }: { timeout?: number }): Promise<{ success: boolean }> {
    log.debug("Start HackleClient initializing")
    let resolveTimeoutPromise: any
    const timeoutPromise: Promise<{ success: boolean }> = new Promise((resolve) => {
      resolveTimeoutPromise = resolve
    })

    const onReadyTimeout = () => {
      resolveTimeoutPromise({
        success: false
      })
    }

    const readyTimeout = setTimeout(onReadyTimeout, timeout)

    this.readyPromise.then(
      () => {
        clearTimeout(readyTimeout)
        resolveTimeoutPromise({
          success: true
        })
      },
      (error: any) => {
        clearTimeout(readyTimeout)
        resolveTimeoutPromise({
          success: false,
          error: error
        })
      }
    )

    return Promise.race([this.readyPromise, timeoutPromise]).then((result: { success: boolean; error?: any }) => {
      if (result.success) {
        log.debug("HackleClient onInitialized Success")
      } else {
        if (result.error instanceof Error) {
          log.error(`HackleClient onInitialized Failed. ${result.error.message}`)
        } else {
          log.error(`HackleClient onInitialized Failed. ${result.error}`)
        }
      }
      return Promise.resolve({ success: result.success })
    })
  }

  close(): void {
    this.workspaceFetcher.close()
    this.eventProcessor.close()
  }
}

export class ErrorDedupDeterminer {
  private previous?: Error

  isDedupTarget(error: Error): boolean {
    if (this._isSameError(error, this.previous)) {
      return true
    }
    this.previous = error
    return false
  }

  _isSameError(currentError: Error, previousError?: Error): boolean {
    if (ObjectUtil.isNullOrUndefined(previousError)) {
      return false
    }
    return (
      currentError.name === previousError.name &&
      currentError.message === previousError.message &&
      currentError.stack === previousError.stack
    )
  }
}
