import { HackleEvent, TargetCondition, TargetKey } from "../../model/model"
import ObjectUtil from "../../util/ObjectUtil"
import { EvaluatorContext } from "../evalautor/Evaluator"
import { EventEvaluatorRequest } from "../evalautor/EventEvaluator"
import ValueOperatorMatcher from "./ValueOperatorMatcher"

export class EventConditionMatcher {
  private readonly eventValueResolver: EventValueResolver
  private readonly valueOperatorMatcher: ValueOperatorMatcher

  constructor(eventValueResolver: EventValueResolver, valueOperatorMatcher: ValueOperatorMatcher) {
    this.eventValueResolver = eventValueResolver
    this.valueOperatorMatcher = valueOperatorMatcher
  }

  matches(request: EventEvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    if (ObjectUtil.isNullOrUndefined(request.event)) {
      return false
    }

    const userValue = this.eventValueResolver.resolveOrNull(request.event, condition.key)
    if (ObjectUtil.isNullOrUndefined(userValue)) {
      return false
    }
    return this.valueOperatorMatcher.matches(userValue, condition.match)
  }
}

export class EventValueResolver {
  resolveOrNull(event: HackleEvent | undefined, key: TargetKey): any {
    switch (key.type) {
      case "EVENT_PROPERTY":
        return event?.properties?.[key.name]
      case "USER_ID":
      case "USER_PROPERTY":
      case "HACKLE_PROPERTY":
      case "SEGMENT":
      case "AB_TEST":
      case "FEATURE_FLAG":
        throw new Error(`Unsupported TargetKeyType [${key.type}]`)
    }
  }
}
