import {
  DecisionReason,
  HackleUser,
  HackleValue,
  MatchValueType,
  RemoteConfigDecision
} from "../../core/internal/model/model"
import ObjectUtil from "../../core/internal/util/ObjectUtil"
import HackleCore from "../../core/HackleCore"

export default class HackleRemoteConfigDecider {
  static decide(core: HackleCore, key: string, defaultValue: HackleValue, user: HackleUser) {
    if (ObjectUtil.isNullOrUndefined(defaultValue)) {
      return this.decision(core, key, "NULL", defaultValue, user)
    }

    switch (typeof defaultValue) {
      case "string":
        return this.decision(core, key, "STRING", defaultValue, user)
      case "number":
        return this.decision(core, key, "NUMBER", defaultValue, user)
      case "boolean":
        return this.decision(core, key, "BOOLEAN", defaultValue, user)
      default:
        return this.decision(core, key, "UNKNOWN", defaultValue, user)
    }
  }

  private static decision(
    core: HackleCore,
    key: string,
    requiredType: MatchValueType,
    defaultValue: HackleValue,
    user: HackleUser
  ): RemoteConfigDecision {
    if (ObjectUtil.isNullOrUndefined(key)) {
      return RemoteConfigDecision.of(defaultValue, DecisionReason.INVALID_INPUT)
    }

    return core.remoteConfig(key, user, requiredType, defaultValue)
  }
}
