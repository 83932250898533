import { EvaluatorContext, EvaluatorEvaluation } from "../Evaluator"
import { Experiment, ParameterConfiguration, Variation, VariationId, VariationKey } from "../../../model/model"
import ExperimentRequest from "./ExperimentRequest"
import Workspace from "../../../workspace/Workspace"
import ObjectUtil from "../../../util/ObjectUtil"

export default class ExperimentEvaluation implements EvaluatorEvaluation {
  readonly reason: string
  readonly targetEvaluations: EvaluatorEvaluation[]
  readonly experiment: Experiment
  readonly variationId: VariationId | undefined
  readonly variationKey: VariationKey
  readonly config: ParameterConfiguration | undefined

  constructor(
    reason: string,
    targetEvaluations: EvaluatorEvaluation[],
    experiment: Experiment,
    variationId: VariationId | undefined,
    variationKey: VariationKey,
    config: ParameterConfiguration | undefined
  ) {
    this.reason = reason
    this.targetEvaluations = targetEvaluations
    this.experiment = experiment
    this.variationId = variationId
    this.variationKey = variationKey
    this.config = config
  }

  with(reason: string): ExperimentEvaluation {
    return new ExperimentEvaluation(
      reason,
      this.targetEvaluations,
      this.experiment,
      this.variationId,
      this.variationKey,
      this.config
    )
  }

  static of(
    request: ExperimentRequest,
    context: EvaluatorContext,
    variation: Variation,
    reason: string
  ): ExperimentEvaluation {
    const config = this.config(request.workspace, variation)
    return new ExperimentEvaluation(
      reason,
      context.targetEvaluations,
      request.experiment,
      variation.id,
      variation.key,
      config
    )
  }

  static ofDefault(request: ExperimentRequest, context: EvaluatorContext, reason: string): ExperimentEvaluation {
    const variation = request.experiment._getVariationByKeyOrNull(request.defaultVariationKey)
    if (ObjectUtil.isNotNullOrUndefined(variation)) {
      return this.of(request, context, variation, reason)
    } else {
      return new ExperimentEvaluation(
        reason,
        context.targetEvaluations,
        request.experiment,
        undefined,
        request.defaultVariationKey,
        undefined
      )
    }
  }

  private static config(workspace: Workspace, variation: Variation): ParameterConfiguration | undefined {
    if (ObjectUtil.isNullOrUndefined(variation.parameterConfigurationId)) {
      return undefined
    }

    return ObjectUtil.requiredNotNullOrUndefined(
      workspace.getParameterConfigurationOrNull(variation.parameterConfigurationId),
      () => `ParameterConfiguration[${variation.parameterConfigurationId}]`
    )
  }
}
