import HackleCore from "../../../core/HackleCore"
import Event from "../../../core/internal/event/Event"
import { HackleEvent, HackleUser, IdentifiersBuilder, IdentifierType, User } from "../../../core/internal/model/model"
import { SessionListener } from "../../../core/internal/session/SessionListener"
import { HackleUserResolver } from "../../user/index.browser"

export class SessionEventTracker implements SessionListener {
  private static SESSION_START_EVENT_NAME = "$session_start"
  private static SESSION_END_EVENT_NAME = "$session_end"

  public static isSessionEvent(event: Event) {
    if (!Event.isTrack(event)) {
      return false
    }

    return (
      event.event.key === SessionEventTracker.SESSION_START_EVENT_NAME ||
      event.event.key === SessionEventTracker.SESSION_END_EVENT_NAME
    )
  }

  constructor(private hackleUserResolver: HackleUserResolver, private core: HackleCore) {}

  onSessionStarted(sessionId: string, user: User, timestamp: number): void {
    this.track(SessionEventTracker.SESSION_START_EVENT_NAME, sessionId, user, timestamp)
  }

  onSessionEnded(sessionId: string, user: User, timestamp: number): void {
    this.track(SessionEventTracker.SESSION_END_EVENT_NAME, sessionId, user, timestamp)
  }

  private track(eventKey: string, sessionId: string, user: User, timestamp: number) {
    const hackleEvent: HackleEvent = { key: eventKey, properties: { sessionId } }
    const hackleUser = this.hackleUserResolver.resolve(user)
    const decoratedUser = this.decorateSession(hackleUser, sessionId)
    this.core.track(hackleEvent, decoratedUser, timestamp)
  }

  private decorateSession(user: HackleUser, sessionId: string): HackleUser {
    const identifiers = new IdentifiersBuilder()
      .add(IdentifierType.SESSION, sessionId)
      .addIdentifiers(user.identifiers)
      .build()
    return {
      ...user,
      identifiers
    }
  }
}
