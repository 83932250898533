import { Clock, SystemClock, TimeUnit } from "../util/TimeUtil"
import { Metric } from "./Metric"
import { MetricRegistry } from "./MetricRegistry"

export interface Timer extends Metric {
  count(): number

  totalTime(unit: TimeUnit): number

  max(unit: TimeUnit): number

  mean(unit: TimeUnit): number

  record(amount: number, unit: TimeUnit): void
}

export class TimerBuilder {
  private _tags: { [key: string]: string } = {}

  constructor(private name: string) {
    this.name = name
  }

  tags(tags: { [key: string]: string }): TimerBuilder {
    Object.entries(tags).forEach(([key, value]) => {
      this._tags[key] = value
    })

    return this
  }

  tag(key: string, value: string) {
    this._tags[key] = value
    return this
  }

  register(registry: MetricRegistry) {
    return registry.registerTimer({ name: this.name, tags: this._tags, type: "TIMER" })
  }
}

export class TimerSample {
  private startTime
  private _clock
  private static defaultClock = new SystemClock()

  constructor(clock: Clock) {
    this._clock = clock
    this.startTime = this._clock.tick()
  }

  static start(clock: Clock = TimerSample.defaultClock): TimerSample {
    return new TimerSample(clock)
  }

  stop(timer: Timer) {
    const now = this._clock.tick()
    const durationMicros = now - this.startTime
    timer.record(durationMicros, "microseconds")
  }
}

export function isTimerMetric(metric: Metric): metric is Timer {
  return metric.id.type === "TIMER"
}
