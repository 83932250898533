import TargetMatcher from "../match/TargetMatcher"
import { RemoteConfigTargetRule } from "../../model/model"
import Bucketer from "../bucket/Bucketer"
import ObjectUtil from "../../util/ObjectUtil"
import RemoteConfigRequest from "../evalautor/remoteconfig/RemoteConfigRequest"
import { EvaluatorContext } from "../evalautor/Evaluator"

export default class RemoteConfigParameterTargetRuleDeterminer {
  private readonly targetMatcher: TargetMatcher
  private readonly bucketer: Bucketer

  constructor(targetMatcher: TargetMatcher, bucketer: Bucketer) {
    this.targetMatcher = targetMatcher
    this.bucketer = bucketer
  }

  determineTargetRuleOrNull(
    request: RemoteConfigRequest,
    context: EvaluatorContext
  ): RemoteConfigTargetRule | undefined {
    return request.parameter.targetRules.find((it) => this.matches(request, context, it))
  }

  private matches(
    request: RemoteConfigRequest,
    context: EvaluatorContext,
    targetRule: RemoteConfigTargetRule
  ): boolean {
    if (!this.targetMatcher.matches(request, context, targetRule.target)) {
      return false
    }

    const identifier = request.user.identifiers[request.parameter.identifierType]

    if (ObjectUtil.isNullOrUndefined(identifier)) {
      return false
    }

    const bucket = request.workspace.getBucketOrNull(targetRule.bucketId)
    if (ObjectUtil.isNullOrUndefined(bucket)) {
      throw new Error(`bucket[${targetRule.bucketId}]`)
    }

    return ObjectUtil.isNotNullOrUndefined(this.bucketer.bucketing(bucket, identifier))
  }
}
