import { PropertiesBuilder } from "./PropertiesBuilder"

export interface PropertyOperator {
  operate(base: Map<string, any>, properties: Map<string, any>): Map<string, any>
}

export class PropertySetOperator implements PropertyOperator {
  operate(base: Map<string, any>, properties: Map<string, any>): Map<string, any> {
    if (base.size === 0) return properties
    if (properties.size === 0) return base

    return new PropertiesBuilder().addProperties(base).addProperties(properties).build()
  }
}

export class PropertySetOnceOperator implements PropertyOperator {
  operate(base: Map<string, any>, properties: Map<string, any>): Map<string, any> {
    if (base.size === 0) return properties
    if (properties.size === 0) return base

    return new PropertiesBuilder().addProperties(base).addProperties(properties, true).build()
  }
}

export class PropertyUnsetOperator implements PropertyOperator {
  operate(base: Map<string, any>, properties: Map<string, any>): Map<string, any> {
    if (base.size === 0) return new Map()
    if (properties.size === 0) return base

    return new PropertiesBuilder().addProperties(base).remove(properties).build()
  }
}

export class PropertyIncrementOperator implements PropertyOperator {
  operate(base: Map<string, any>, properties: Map<string, any>): Map<string, any> {
    if (properties.size === 0) return base

    const builder = new PropertiesBuilder()
    builder.addProperties(base)

    properties.forEach((value, key) => {
      builder.compute(key, (mapper) => this.increment(mapper, value))
    })

    return builder.build()
  }

  private increment(baseValue: unknown, valueToIncrement: unknown): any {
    if (typeof valueToIncrement !== "number") return baseValue
    if (typeof baseValue !== "number") {
      if (baseValue === undefined || baseValue === null) return valueToIncrement
      return baseValue
    }

    return baseValue + valueToIncrement
  }
}

export abstract class ArrayPropertyOperator implements PropertyOperator {
  operate(base: Map<string, any>, properties: Map<string, any>): Map<string, any> {
    if (properties.size === 0) return base

    const builder = new PropertiesBuilder()
    builder.addProperties(base)

    properties.forEach((value, key) => {
      builder.compute(key, (mapper) => this.compute(mapper, value))
    })

    return builder.build()
  }

  private compute(baseValue: unknown, valueToCompute: unknown): any[] {
    const base = this.toArray(baseValue)
    const values = this.toArray(valueToCompute)
    return this.operateArray(base, values)
  }

  private toArray(value: unknown): any[] {
    if (value === undefined || value === null) return []
    if (Array.isArray(value)) return value

    return [value]
  }

  private contains(base: any[], value: any): boolean {
    return base.includes(value)
  }

  protected prepend(value: any, base: any[], setOnce: boolean = false): any[] {
    if (setOnce && this.contains(base, value)) return base

    return [value, ...base]
  }

  protected append(base: any[], value: any, setOnce: boolean = false): any[] {
    if (setOnce && this.contains(base, value)) return base

    return [...base, value]
  }

  abstract operateArray(base: any[], values: any[]): any[]
}

export class PropertyAppendOperator extends ArrayPropertyOperator {
  operateArray(base: any[], values: any[]): any[] {
    return values.reduce((acc, cur) => this.append.bind(this)(acc, cur), base)
  }
}

export class PropertyAppendOnceOperator extends ArrayPropertyOperator {
  operateArray(base: any[], values: any[]): any[] {
    return values.reduce(this.appendOnce.bind(this), base)
  }

  private appendOnce(base: any[], value: any): any[] {
    return this.append(base, value, true)
  }
}

export class PropertyPrependOperator extends ArrayPropertyOperator {
  operateArray(base: any[], values: any[]): any[] {
    return values.reduceRight((acc, cur) => this.prepend.bind(this)(cur, acc), base)
  }
}

export class PropertyPrependOnceOperator extends ArrayPropertyOperator {
  operateArray(base: any[], values: any[]): any[] {
    return values
      .reduce(this.appendOnce.bind(this), [])
      .reduceRight((acc, cur) => this.prependOnce.bind(this)(cur, acc), base)
  }

  private appendOnce(base: any[], value: any): any[] {
    return this.append(base, value, true)
  }

  private prependOnce(value: any, base: any[]): any[] {
    return this.prepend(value, base, true)
  }
}

export class PropertyRemoveOperator extends ArrayPropertyOperator {
  operateArray(base: any[], values: any[]): any[] {
    return values.reduce(this.remove.bind(this), base)
  }

  private remove(builder: any[], value: any): any[] {
    return builder.filter((element) => element !== value)
  }
}

export class PropertyClearAllOperator implements PropertyOperator {
  operate(base: Map<string, any>, properties: Map<string, any>): Map<string, any> {
    return new Map()
  }
}
