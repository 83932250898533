import { Bucket, Slot } from "../../model/model"
import Murmur3 from "./Murmur3"

export default class Bucketer {
  bucketing(bucket: Bucket, identifier: string): Slot | undefined {
    const slotNumber = this.calculateSlotNumber(bucket.seed, bucket.slotSize, identifier)
    return bucket.slots.find((it) => it.contains(slotNumber))
  }

  calculateSlotNumber(seed: number, slotSize: number, value: string): number {
    const hashValue = Murmur3.murmurhash3_x86_32(value, seed)
    return Math.abs(hashValue) % Math.abs(slotSize)
  }
}
