import { Target, TargetCondition } from "../../model/model"
import { EvaluatorContext } from "../evalautor/Evaluator"
import { EventEvaluatorRequest } from "../evalautor/EventEvaluator"
import { InAppMessageConditionMatcherFactory } from "./InAppMessageConditionMatcherFactory"

export default class InAppMessageTargetMatcher {
  private conditionMatcherFactory: InAppMessageConditionMatcherFactory

  constructor(conditionMatcherFactory: InAppMessageConditionMatcherFactory) {
    this.conditionMatcherFactory = conditionMatcherFactory
  }

  matches(request: EventEvaluatorRequest, context: EvaluatorContext, target: Target): boolean {
    return target.conditions.every((it) => this._matches(request, context, it))
  }

  private _matches(request: EventEvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    const conditionMatcher = this.conditionMatcherFactory.getMatcher(condition.key.type)
    return conditionMatcher.matches(request, context, condition)
  }
}
