import HacklePropertyGenerator from "../property/HacklePropertyGenerator"
import { v4 as uuid4 } from "uuid"
import { COOKIE_HID_KEY, COOKIE_USER_ID_KEY } from "../../config"
import { HackleUser, IdentifiersBuilder, IdentifierType, Properties, User } from "../../core/internal/model/model"
import { cookieStorage } from "../storage/CookieStorage.browser"
import { UserManager } from "../../core/internal/user/UserManager"
import LocationSearchParser from "../property/LocationSearchParser"
import ObjectUtil from "../../core/internal/util/ObjectUtil"

export class HackleUserResolver {

  private readonly userManager: UserManager

  constructor(userManager: UserManager) {
    this.userManager = userManager
    IdentifierManager.initialize()
  }

  resolve(user: User): HackleUser {
    const hackleDeviceId = getUserId()
    const builder = new IdentifiersBuilder()
      .addIdentifiers(user.identifiers || {})
      .add(IdentifierType.ID, user.id || hackleDeviceId)
      .add(IdentifierType.DEVICE, user.deviceId || hackleDeviceId)
      .add(IdentifierType.HACKLE_DEVICE, hackleDeviceId)

    if (ObjectUtil.isNotNullOrUndefined(user.userId)) {
      builder.add(IdentifierType.USER, user.userId)
    }
    const identifiers = builder.build()
    return {
      identifiers: identifiers,
      properties: this._userProperties(user.properties),
      hackleProperties: this._getHackleProperties()
    }
  }

  resolveCurrentUser(): HackleUser {
    return this.resolve(this.userManager.currentUser)
  }

  _userProperties(properties: Properties = {}): Properties {
    let userProperties = {}

    if (typeof window !== "undefined") {
      const parser = new LocationSearchParser(window.location.search)
      userProperties = {
        ...parser.getQueryParams((key) => key.startsWith("utm_")),
        ...parser.getQueryParams((key) => ["gclid", "fbclid"].includes(key))
      }
    }

    return {
      ...userProperties,
      ...properties
    }
  }

  _getHackleProperties(): Properties {
    let hackleProperties
    // @ts-ignore
    if (typeof window !== "undefined") {
      // @ts-ignore
      hackleProperties = HacklePropertyGenerator.generate(window)
    }
    return hackleProperties || {}
  }
}

export class IdentifierManager {
  static initialize() {
    const id = cookieStorage.getItem(COOKIE_HID_KEY)
    if (!id) {
      generateIdentifier()
    }
  }

  static reInitialize() {
    generateIdentifier()
  }
}

export function getUserId(): string {
  const userId = cookieStorage.getItem(COOKIE_USER_ID_KEY)
  if (userId) {
    return userId
  }
  const id = cookieStorage.getItem(COOKIE_HID_KEY)
  if (id) {
    return id
  } else {
    return generateIdentifier()
  }
}

export function setUserId(userId: string): void {
  cookieStorage.setItem(COOKIE_USER_ID_KEY, userId)
}

export function removeUserId(): void {
  cookieStorage.removeItem(COOKIE_USER_ID_KEY)
}

export function generateIdentifier(): string {
  const id = uuid4()
  cookieStorage.setItem(COOKIE_HID_KEY, id)
  return id
}
