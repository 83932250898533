import { EvaluatorKey, EvaluatorRequest } from "../Evaluator"
import { Experiment, HackleUser, VariationKey } from "../../../model/model"
import Workspace from "../../../workspace/Workspace"

export default class ExperimentRequest implements EvaluatorRequest {
  readonly key: EvaluatorKey
  readonly workspace: Workspace
  readonly user: HackleUser
  readonly experiment: Experiment
  readonly defaultVariationKey: VariationKey

  private constructor(
    workspace: Workspace,
    user: HackleUser,
    experiment: Experiment,
    defaultVariationKey: VariationKey
  ) {
    this.key = new EvaluatorKey("EXPERIMENT", experiment.id)
    this.workspace = workspace
    this.user = user
    this.experiment = experiment
    this.defaultVariationKey = defaultVariationKey
  }

  static of(
    workspace: Workspace,
    user: HackleUser,
    experiment: Experiment,
    defaultVariationKey: VariationKey
  ): ExperimentRequest {
    return new ExperimentRequest(workspace, user, experiment, defaultVariationKey)
  }

  static by(requestedBy: EvaluatorRequest, experiment: Experiment): ExperimentRequest {
    return new ExperimentRequest(requestedBy.workspace, requestedBy.user, experiment, "A")
  }

  toString(): string {
    return `EvaluatorRequest(type=${this.experiment.type}, key=${this.experiment.key})`
  }
}
