import OperatorMatcher from "./OperatorMatcher"
import { MatchValueType, Version } from "../../model/model"
import ObjectUtil from "../../util/ObjectUtil"
import TypeUtil from "../../util/TypeUtil"

export default interface ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean
}

export class StringMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    const typedUserValue = TypeUtil.asString(userValue)
    const typedMatchValue = TypeUtil.asString(matchValue)
    if (ObjectUtil.isNullOrUndefined(typedUserValue) || ObjectUtil.isNullOrUndefined(typedMatchValue)) {
      return false
    }
    return operatorMatcher.stringMatches(typedUserValue, typedMatchValue)
  }
}

export class NumberMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    const typedUserValue = TypeUtil.asNumber(userValue)
    const typedMatchValue = TypeUtil.asNumber(matchValue)
    if (ObjectUtil.isNullOrUndefined(typedUserValue) || ObjectUtil.isNullOrUndefined(typedMatchValue)) {
      return false
    }
    return operatorMatcher.numberMatches(typedUserValue, typedMatchValue)
  }
}

export class BooleanMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    if (typeof userValue === "boolean" && typeof matchValue === "boolean") {
      return operatorMatcher.booleanMatches(userValue, matchValue)
    } else {
      return false
    }
  }
}

export class VersionMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    const userVersion = Version.tryParse(userValue)
    const matchVersion = Version.tryParse(matchValue)
    if (userVersion && matchVersion) {
      return operatorMatcher.versionMatches(userVersion, matchVersion)
    } else {
      return false
    }
  }
}

export class NullMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    return false
  }
}

export class ValueMatcherFactory {
  private static STRING_MATCHER = new StringMatcher()
  private static NUMBER_MATCHER = new NumberMatcher()
  private static BOOLEAN_MATCHER = new BooleanMatcher()
  private static VERSION_MATCHER = new VersionMatcher()
  private static NULL_MATCHER = new NullMatcher()

  getMatcher(valueType: MatchValueType): ValueMatcher {
    switch (valueType) {
      case "STRING":
        return ValueMatcherFactory.STRING_MATCHER
      case "JSON":
        return ValueMatcherFactory.STRING_MATCHER
      case "NUMBER":
        return ValueMatcherFactory.NUMBER_MATCHER
      case "BOOLEAN":
        return ValueMatcherFactory.BOOLEAN_MATCHER
      case "VERSION":
        return ValueMatcherFactory.VERSION_MATCHER
      case "NULL":
        return ValueMatcherFactory.NULL_MATCHER
      case "UNKNOWN":
        return ValueMatcherFactory.NULL_MATCHER
    }
  }
}
