import { MatchType, TargetMatch } from "../../model/model"
import OperatorMatcher, { OperatorMatcherFactory } from "./OperatorMatcher"
import ValueMatcher, { ValueMatcherFactory } from "./ValueMatcher"

export default class ValueOperatorMatcher {

  private valueMatcherFactory: ValueMatcherFactory
  private operatorMatcherFactory: OperatorMatcherFactory

  constructor(valueMatcherFactory: ValueMatcherFactory, operatorMatcherFactory: OperatorMatcherFactory) {
    this.valueMatcherFactory = valueMatcherFactory
    this.operatorMatcherFactory = operatorMatcherFactory
  }

  matches(userValue: any, match: TargetMatch): boolean {
    const valueMatcher = this.valueMatcherFactory.getMatcher(match.valueType)
    const operatorMatcher = this.operatorMatcherFactory.getMatcher(match.operator)
    const isMatched = this.userValueMatches(userValue, match, valueMatcher, operatorMatcher)
    return this.typeMatches(match.type, isMatched)
  }

  private userValueMatches(
    userValue: any,
    match: TargetMatch,
    valueMatcher: ValueMatcher,
    operatorMatcher: OperatorMatcher
  ): boolean {
    if (Array.isArray(userValue)) {
      return this.arrayMatches(userValue, match, valueMatcher, operatorMatcher)
    }
    return this.singleMatches(userValue, match, valueMatcher, operatorMatcher)
  }

  private singleMatches(
    userValue: any,
    match: TargetMatch,
    valueMatcher: ValueMatcher,
    operatorMatcher: OperatorMatcher
  ): boolean {
    return match.values.some((it) => valueMatcher.matches(operatorMatcher, userValue, it))
  }

  private arrayMatches(
    userValues: any[],
    match: TargetMatch,
    valueMatcher: ValueMatcher,
    operatorMatcher: OperatorMatcher
  ): boolean {
    return userValues.some((it) => this.singleMatches(it, match, valueMatcher, operatorMatcher))
  }

  private typeMatches(matchType: MatchType, isMatched: boolean): boolean {
    switch (matchType) {
      case "MATCH":
        return isMatched
      case "NOT_MATCH":
        return !isMatched
    }
  }
}
