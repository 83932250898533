import { Experiment, HackleUser, Long, Variation } from "../../model/model"
import { ListStorage } from "../../storage/Storage"
import TypeUtil from "../../util/TypeUtil"
import ObjectUtil from "../../util/ObjectUtil"

export interface ManualOverrideStorage {
  getVariation(experiment: Experiment, user: HackleUser): Variation | undefined
}

export class DelegatingManualOverrideStorage implements ManualOverrideStorage {
  constructor(private storages: ManualOverrideStorage[]) {}

  getVariation(experiment: Experiment, user: HackleUser): Variation | undefined {
    if (this.storages.length === 0) return

    for (const storage of this.storages) {
      const _variation = storage.getVariation(experiment, user)

      if (_variation !== undefined) {
        return _variation
      }
    }

    return undefined
  }
}

export class HackleUserManualOverrideStorage implements ManualOverrideStorage {
  constructor(private listStorage: ListStorage) {}

  getVariation(experiment: Experiment, user: HackleUser): Variation | undefined {
    const variationId = this.get(experiment)
    if (!variationId) return undefined
    return experiment._getVariationByIdOrNull(variationId)
  }

  get(experiment: Experiment): Long | undefined {
    const variationId = this.listStorage.getItem(experiment.id.toString())
    return TypeUtil.asNumber(variationId)
  }

  getAll(): Map<Long, Long> {
    const results = new Map<Long, Long>()
    this.listStorage.entries().forEach(([key, value]) => {
      const experimentId = TypeUtil.asNumber(key)
      const variationId = TypeUtil.asNumber(value)

      if (ObjectUtil.isNullOrUndefined(experimentId) || experimentId < 0) {
        return
      }

      if (ObjectUtil.isNullOrUndefined(variationId) || experimentId < 0) {
        return
      }

      results.set(experimentId, variationId)
    })
    return results
  }

  set(experiment: Experiment, variationId: Long): void {
    this.listStorage.setItem(experiment.id.toString(), variationId.toString())
  }

  remove(experiment: Experiment) {
    this.listStorage.removeItem(experiment.id.toString())
  }

  clear() {
    this.listStorage.clear()
  }
}
