import Bucketer from "../bucket/Bucketer"
import { Container } from "../../model/model"
import ExperimentRequest from "../evalautor/experiment/ExperimentRequest"
import { EvaluatorContext } from "../evalautor/Evaluator"
import ObjectUtil from "../../util/ObjectUtil"

export default class ContainerResolver {

  private readonly bucketer: Bucketer

  constructor(bucketer: Bucketer) {
    this.bucketer = bucketer
  }

  isUserInContainerGroup(request: ExperimentRequest, context: EvaluatorContext, container: Container): boolean {
    const identifier = request.user.identifiers[request.experiment.identifierType]
    if (ObjectUtil.isNullOrUndefined(identifier)) {
      return false
    }

    const bucket = request.workspace.getBucketOrNull(container.bucketId)
    if (ObjectUtil.isNullOrUndefined(bucket)) {
      throw new Error(`Bucket[${container.bucketId}]`)
    }

    const slot = this.bucketer.bucketing(bucket, identifier)
    if (ObjectUtil.isNullOrUndefined(slot)) {
      return false
    }

    const containerGroup = container.getGroupOrNull(slot.variationId)
    if (ObjectUtil.isNullOrUndefined(containerGroup)) {
      throw new Error(`ContainerGroup[${slot.variationId}]`)
    }

    return containerGroup.experiments.includes(request.experiment.id)
  }
}
