import { EvaluatorKey } from "../Evaluator"
import Workspace from "../../../workspace/Workspace"
import { HackleUser, IAM } from "../../../model/model"
import { EventEvaluatorRequest } from "../EventEvaluator"

export class InAppMessageRequest implements EventEvaluatorRequest {
  readonly key: EvaluatorKey
  readonly workspace: Workspace
  readonly user: HackleUser
  readonly timestamp: number
  readonly message: IAM

  constructor(workspace: Workspace, message: IAM, user: HackleUser, timestamp: number) {
    this.key = new EvaluatorKey("IN_APP_MESSAGE", message.key)
    this.workspace = workspace
    this.user = user
    this.message = message
    this.timestamp = timestamp
  }

  static of(workspace: Workspace, message: IAM, user: HackleUser, timestamp: number): InAppMessageRequest {
    return new InAppMessageRequest(workspace, message, user, timestamp)
  }

  toString(): string {
    return `EvaluatorRequest(type=${this.key.type}, key=${this.message.key})`
  }
}
