export default class LocationSearchParser {
  search: string

  constructor(search: string) {
    this.search = search
  }

  getQueryParam(name: string): string {
    const params = this.getQueryParams(() => true)
    return params[name] || ""
  }

  getQueryParams(condition: (key: string, value: string) => boolean) {
    const params: { [key: string]: string } = {}
    const queries = this.search.substring(1).split("&")

    for (const query of queries) {
      const [key, value] = query.split("=")
      if (condition(key, value)) {
        params[key] = decodeURIComponent(value)
      }
    }

    return params
  }
}
