import TargetMatcher from "../match/TargetMatcher"
import { TargetRule } from "../../model/model"
import ExperimentRequest from "../evalautor/experiment/ExperimentRequest"
import { EvaluatorContext } from "../evalautor/Evaluator"

export default class TargetRuleDeterminer {
  private readonly targetMatcher: TargetMatcher

  constructor(targetMatcher: TargetMatcher) {
    this.targetMatcher = targetMatcher
  }

  determineTargetRuleOrNull(request: ExperimentRequest, context: EvaluatorContext): TargetRule | undefined {
    return request.experiment.targetRules.find((it) => this.targetMatcher.matches(request, context, it.target))
  }
}
