import { Comparable } from "../../util/Comparable"
import { MetricId } from "../Metric"
import { MetricRegistry } from "../MetricRegistry"
import { DelegatingCounter } from "./DelegatingCounter"
import { DelegatingMetric, isDelegatingMetric } from "./DelegatingMetric"
import { DelegatingTimer } from "./DelegatingTimer"

export class DelegatingMetricRegistry extends MetricRegistry {
  private registries = new Comparable<MetricRegistry>((a, b) => a.id === b.id)

  createCounter(id: MetricId) {
    const counter = new DelegatingCounter(id)
    this.addRegistry(counter)

    return counter
  }

  createTimer(id: MetricId) {
    const timer = new DelegatingTimer(id)
    this.addRegistry(timer)

    return timer
  }

  private addRegistry(metric: DelegatingMetric) {
    this.registries.forEach((registry) => metric.add(registry))
  }

  add(registry: MetricRegistry) {
    if (registry instanceof DelegatingMetricRegistry) return
    if (this.registries.has(registry)) return

    this.registries.add(registry)
    this.metrics.forEach((metric) => {
      if (!isDelegatingMetric(metric)) return
      metric.add(registry)
    })
  }

  close() {
    this.registries.forEach((registry) => {
      registry.close()
    })
  }
}
