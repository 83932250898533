import { TargetKeyType } from "../../model/model"
import ConditionMatcher from "./ConditionMatcher"
import { EventConditionMatcher, EventValueResolver } from "./EventConditionMatcher"
import { OperatorMatcherFactory } from "./OperatorMatcher"
import SegmentConditionMatcher, { SegmentMatcher } from "./SegmentConditionMatcher"
import UserConditionMatcher, { UserValueResolver } from "./UserConditionMatcher"
import { ValueMatcherFactory } from "./ValueMatcher"
import ValueOperatorMatcher from "./ValueOperatorMatcher"

export class InAppMessageConditionMatcherFactory {
  private readonly userConditionMatcher: ConditionMatcher
  private readonly segmentConditionMatcher: ConditionMatcher
  private readonly eventConditionMatcher: EventConditionMatcher

  constructor() {
    const valueOperatorMatcher = new ValueOperatorMatcher(new ValueMatcherFactory(), new OperatorMatcherFactory())
    const userConditionMatcher = new UserConditionMatcher(new UserValueResolver(), valueOperatorMatcher)
    this.userConditionMatcher = userConditionMatcher
    this.segmentConditionMatcher = new SegmentConditionMatcher(new SegmentMatcher(userConditionMatcher))
    this.eventConditionMatcher = new EventConditionMatcher(new EventValueResolver(), valueOperatorMatcher)
  }

  getMatcher(type: TargetKeyType): ConditionMatcher | EventConditionMatcher {
    switch (type) {
      case "USER_ID":
      case "USER_PROPERTY":
      case "HACKLE_PROPERTY":
        return this.userConditionMatcher
      case "SEGMENT":
        return this.segmentConditionMatcher
      case "AB_TEST":
      case "FEATURE_FLAG":
        throw new Error(`Unsupported TargetKeyType [${type}]`)
      case "EVENT_PROPERTY":
        return this.eventConditionMatcher
    }
  }
}
