import IdentifierUtil from "../util/IdentifierUtil"

export type MetricType = "COUNTER" | "TIMER"

export interface MetricId {
  name: string
  tags: { [key: string]: string }
  type: MetricType
}

export type MetricField = "count" | "total" | "max" | "mean"

export class Measurement {
  public field
  constructor(field: MetricField, private valueSupplier: () => number) {
    this.field = field
  }

  get value() {
    return this.valueSupplier()
  }
}

export interface Metric {
  id: MetricId
  measure(): Measurement[]
}

export function isMetricIdEqual(lid: MetricId, rid: MetricId) {
  return lid.name === rid.name && IdentifierUtil.isEquals(lid.tags, rid.tags)
}
