import { Counter } from "../Counter"
import { CumulativeCounter } from "../cumulative/CumulativeCounter"
import { Metric, Measurement, MetricId } from "../Metric"
import { FlushMetric } from "./FlushMetric"

export class FlushCounter implements Counter, FlushMetric {
  public id: MetricId
  private currentCounter: Counter

  constructor(id: MetricId) {
    this.id = id
    this.currentCounter = new CumulativeCounter(id)
  }

  flush(): Metric {
    const prev = this.currentCounter
    this.currentCounter = new CumulativeCounter(this.id)
    return prev
  }

  count(): number {
    return this.currentCounter.count()
  }

  increment(delta?: number): void {
    this.currentCounter.increment(delta)
  }

  measure(): Measurement[] {
    return [new Measurement("count", this.count.bind(this))]
  }
}
