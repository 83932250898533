import { Comparable } from "../../util/Comparable"
import { Counter } from "../Counter"
import { Measurement, MetricId } from "../Metric"
import { MetricRegistry } from "../MetricRegistry"
import { NoopCounter } from "../noop/NoopCounter"
import { DelegatingMetric } from "./DelegatingMetric"

export class DelegatingCounter implements Counter, DelegatingMetric {
  id: MetricId
  private noopCounter: Counter
  private _counters: Comparable<MetricRegistry, Counter> = new Comparable((a, b) => a.id === b.id)

  constructor(id: MetricId) {
    this.id = id
    this.noopCounter = new NoopCounter(id)
  }

  get counters() {
    return Array.from(this._counters.values())
  }

  add(registry: MetricRegistry): void {
    this._counters.add(registry, registry.registerCounter(this.id))
  }

  count(): number {
    return (this.counters[0] || this.noopCounter).count()
  }

  increment(delta?: number): void {
    this.counters.forEach((counter) => {
      counter.increment(delta)
    })
  }

  measure(): Measurement[] {
    return [new Measurement("count", this.count.bind(this))]
  }
}
