import TargetMatcher from "../match/TargetMatcher"
import ExperimentRequest from "../evalautor/experiment/ExperimentRequest"
import { EvaluatorContext } from "../evalautor/Evaluator"

export default class ExperimentTargetDeterminer {
  private readonly targetMatcher: TargetMatcher

  constructor(targetMatcher: TargetMatcher) {
    this.targetMatcher = targetMatcher
  }

  isUserInExperimentTarget(request: ExperimentRequest, context: EvaluatorContext): boolean {
    if (request.experiment.targetAudiences.length === 0) {
      return true
    }

    return request.experiment.targetAudiences.some((it) => this.targetMatcher.matches(request, context, it))
  }
}
