export class Comparable<T, V = T> {
  private data: Map<T, V> = new Map()
  constructor(private compareFn: (a: T, b: T) => boolean) {
    this.compareFn = compareFn
    this.data = new Map()
  }

  values() {
    return Array.from(this.data.values())
  }

  keys() {
    return Array.from(this.data.keys())
  }

  get(key: T): V | undefined {
    const targetKey = this.keys().find((innerKey) => this.compareFn(innerKey, key))
    return targetKey ? this.data.get(targetKey) : undefined
  }

  has(value: T): boolean {
    return this.get(value) !== undefined
  }

  add(key: T, value?: V): this {
    let addValue = value
    if (!addValue) {
      addValue = key as unknown as V
    }

    this.data.set(key, addValue)
    return this
  }

  delete(key: T): V | undefined {
    if (!this.has(key)) return undefined

    const targetValue = this.get(key)
    this.data.delete(key)

    return targetValue
  }

  forEach(cb: (value: V, key: T) => void) {
    this.data.forEach(cb)
  }

  entries() {
    return Array.from(this.data.entries())
  }
}
