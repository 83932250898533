import { EvaluatorKey, EvaluatorRequest } from "../Evaluator"
import Workspace from "../../../workspace/Workspace"
import { HackleUser, HackleValue, MatchValueType, RemoteConfigParameter } from "../../../model/model"

export default class RemoteConfigRequest implements EvaluatorRequest {

  readonly key: EvaluatorKey
  readonly workspace: Workspace
  readonly user: HackleUser
  readonly parameter: RemoteConfigParameter
  readonly requiredType: MatchValueType
  readonly defaultValue: HackleValue

  private constructor(
    workspace: Workspace,
    user: HackleUser,
    parameter: RemoteConfigParameter,
    requiredType: MatchValueType,
    defaultValue: HackleValue
  ) {
    this.key = new EvaluatorKey("REMOTE_CONFIG", parameter.id)
    this.workspace = workspace
    this.user = user
    this.parameter = parameter
    this.requiredType = requiredType
    this.defaultValue = defaultValue
  }

  static of(
    workspace: Workspace,
    user: HackleUser,
    parameter: RemoteConfigParameter,
    requiredType: MatchValueType,
    defaultValue: HackleValue
  ): RemoteConfigRequest {
    return new RemoteConfigRequest(workspace, user, parameter, requiredType, defaultValue)
  }

  toString(): string {
    return `EvaluatorRequest(type=${this.key.type}, key=${this.parameter.key})`
  }
}
