import { EvaluatorKey } from "../Evaluator"
import Workspace from "../../../workspace/Workspace"
import { HackleEvent, HackleUser } from "../../../model/model"
import { Track } from "../../../event/Event"
import { EventEvaluatorRequest } from "../EventEvaluator"

export class TrackInAppMessageRequest implements EventEvaluatorRequest {
  readonly key: EvaluatorKey
  readonly workspace: Workspace
  readonly user: HackleUser
  readonly event: HackleEvent
  readonly track: Track

  constructor(workspace: Workspace, track: Track) {
    this.key = new EvaluatorKey("IN_APP_MESSAGE", track.insertId)
    this.workspace = workspace
    this.track = track
    this.user = track.user
    this.event = track.event
  }

  static of(workspace: Workspace, track: Track): TrackInAppMessageRequest {
    return new TrackInAppMessageRequest(workspace, track)
  }

  toString(): string {
    return `EvaluatorRequest(type=${this.key.type}, key=${this.track.insertId})`
  }
}
