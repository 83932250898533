import { EventDispatcherConfig } from "../../../core/internal/event/EventDispatcher"
import { SDK_KEY_HEADER, SDK_NAME_HEADER, SDK_TIME_HEADER, SDK_VERSION_HEADER } from "../../../config"
import { EventDto, toPayload } from "../../../core/internal/event/dto"
import Event from "../../../core/internal/event/Event"
import { Headers, HttpRequest } from "../../../core/internal/http"
import { TimerSample } from "../../../core/internal/metrics/Timer"
import { ApiCallMetrics } from "../../../core/internal/metrics/monitoring/MonitoringMetricRegistry"

export type EventDispatcherResponse = {
  statusCode: number
}

export type EventDispatcherCallback = (response: EventDispatcherResponse) => void

export default class EventDispatcher {
  private readonly sdkKey: string
  private readonly httpRequest: HttpRequest
  private readonly headers: EventDispatcherConfig["headers"]
  private readonly dispatchUrl: string
  private readonly beaconDispatchUrl: string
  private readonly useBeacon: boolean

  constructor(sdkKey: string, httpRequest: HttpRequest, config: EventDispatcherConfig) {
    this.sdkKey = sdkKey
    this.httpRequest = httpRequest
    this.headers = config.headers
    this.dispatchUrl = config.dispatchUrl
    this.beaconDispatchUrl = config.beaconDispatchUrl || ""
    this.useBeacon = config.useBeacon || false
  }

  dispatch<T>(
    events: Array<Event | EventDto>,
    callback: EventDispatcherCallback,
    errorCallback?: EventDispatcherCallback
  ): void {
    const headers: Headers = {}
    headers[SDK_KEY_HEADER] = this.sdkKey
    headers[SDK_NAME_HEADER] = this.headers[SDK_NAME_HEADER]
    headers[SDK_VERSION_HEADER] = this.headers[SDK_VERSION_HEADER]
    headers[SDK_TIME_HEADER] = new Date().getTime().toString()

    const payload = toPayload(events)
    const sample = TimerSample.start()
    this.httpRequest.postRequest(
      this.dispatchUrl,
      payload,
      headers,
      (response: EventDispatcherResponse) => {
        callback(response)
        ApiCallMetrics.record("post.events", sample, true)
      },
      (response: EventDispatcherResponse) => {
        errorCallback?.(response)
        ApiCallMetrics.record("post.events", sample, false)
      }
    )
  }

  dispatchXhrOrBeacon<T>(
    events: Array<Event | EventDto>,
    callback: EventDispatcherCallback,
    errorCallback?: EventDispatcherCallback
  ): void {
    if (this.useBeacon) {
      const headers: Headers = {}
      headers[SDK_KEY_HEADER] = this.sdkKey
      headers[SDK_NAME_HEADER] = this.headers[SDK_NAME_HEADER]
      headers[SDK_VERSION_HEADER] = this.headers[SDK_VERSION_HEADER]
      headers[SDK_TIME_HEADER] = new Date().getTime().toString()

      const payload = toPayload(events)
      const sample = TimerSample.start()

      this.httpRequest.sendBeacon(
        this.beaconDispatchUrl,
        payload,
        headers,
        (response: EventDispatcherResponse) => {
          callback(response)
          ApiCallMetrics.record("post.events", sample, true)
        },
        (response: EventDispatcherResponse) => {
          errorCallback?.(response)
          ApiCallMetrics.record("post.events", sample, false)
        }
      )
    } else {
      this.dispatch(events, callback, errorCallback)
    }
  }
}
