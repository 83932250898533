import { DecisionReason, IAM } from "../../../model/model"
import { InAppMessageDeterminer } from "../../iam/InAppMessageDeterminer"
import ContextualEvaluator from "../ContextualEvaluator"
import { EvaluatorContext, EvaluatorRequest } from "../Evaluator"
import { InAppMessageEvaluation } from "./InAppMessageEvaluation"
import { InAppMessageRequest } from "./InAppMessageRequest"

export class InAppMessageEvaluator extends ContextualEvaluator<InAppMessageRequest, InAppMessageEvaluation> {
  constructor(private readonly determiner: InAppMessageDeterminer) {
    super()
  }

  public supports(request: EvaluatorRequest): request is InAppMessageRequest {
    return !!("message" in request && (request as InAppMessageRequest).message instanceof IAM)
  }

  public evaluateInternal(request: InAppMessageRequest, context: EvaluatorContext): InAppMessageEvaluation {
    const message = this.determiner.determineInAppMessageTargetRule(request, context)

    if (!message) {
      return new InAppMessageEvaluation(DecisionReason.DEFAULT_RULE, context.targetEvaluations, message)
    }

    return new InAppMessageEvaluation(DecisionReason.TARGET_RULE_MATCH, context.targetEvaluations, message)
  }
}
