import HackleCore from "../../HackleCore"
import Event from "../event/Event"
import { IAM } from "../model/model"
import { Emitter } from "../util/emitter"

export interface InAppMessageTriggerManagerCampaignEventPayload {
  message: IAM
  timestamp: number
}

interface InAppMessageTriggerManagerEventMap {
  campaign: InAppMessageTriggerManagerCampaignEventPayload
}

export class InAppMessageTriggerManager extends Emitter<InAppMessageTriggerManagerEventMap> {
  constructor(private core: HackleCore) {
    super()
    this.initialize()
  }

  private initialize() {
    this.core.eventProcessor.on("queue", this.handleTrackEventTrigger)
    this.core.workspaceFetcher.start()
  }

  public destroy() {
    this.core.eventProcessor.off("queue", this.handleTrackEventTrigger)
  }

  private handleTrackEventTrigger = (event: Event) => {
    if (!Event.isTrack(event)) {
      return
    }

    const triggerDecision = this.core.trackInAppMessage(event)

    if (!triggerDecision.value) {
      return
    }

    const decision = this.core.inAppMessage(triggerDecision.value.key, event.user, event.timestamp)

    if (!decision.value) {
      return
    }

    this.emit("campaign", { message: decision.value, timestamp: event.timestamp })
  }
}
