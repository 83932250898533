import Bucketer from "../bucket/Bucketer"
import { TargetAction, Variation } from "../../model/model"
import ExperimentRequest from "../evalautor/experiment/ExperimentRequest"
import { EvaluatorContext } from "../evalautor/Evaluator"

export default class ActionResolver {
  private readonly bucketer: Bucketer

  constructor(bucketer: Bucketer) {
    this.bucketer = bucketer
  }

  resolveOrNull(request: ExperimentRequest, context: EvaluatorContext, action: TargetAction): Variation | undefined {
    switch (action.type) {
      case "VARIATION":
        return this.resolveVariation(request, action)
      case "BUCKET":
        return this.resolveBucket(request, action)
    }
  }

  private resolveVariation(request: ExperimentRequest, action: TargetAction): Variation {
    return request.experiment._getVariationByIdOrNull(action.variationId!)!
  }

  private resolveBucket(request: ExperimentRequest, action: TargetAction): Variation | undefined {
    const bucket = request.workspace.getBucketOrNull(action.bucketId!)!

    const identifier = request.user.identifiers[request.experiment.identifierType]
    if (!identifier) {
      return undefined
    }

    const allocatedSlot = this.bucketer.bucketing(bucket, identifier)
    if (!allocatedSlot) {
      return undefined
    }

    return request.experiment._getVariationByIdOrNull(allocatedSlot.variationId)
  }
}

