import UAParser from "./UAParser"
import { Properties } from "../../core/internal/model/model"

type WindowLocation = {
  hostname: string
  pathname: string
  host: string
  protocol: string
  search: string
}
type WindowDocument = {
  location: WindowLocation,
  title: string,
  referrer: string,
}
type WindowNavigator = {
  userAgent: string,
  vendor?: string,
  languages?: Array<string>,
}
type WindowScreen = {
  width: number,
  height: number,
}
type Window = {
  navigator: WindowNavigator,
  document: WindowDocument,
  location: WindowLocation,
  screen: WindowScreen
}

export default class HacklePropertyGenerator {
  static generate(global?: any): Properties {
    let win
    // Server-Side Ignore
    if (typeof (global) === "undefined") {
      const loc = {
        hostname: "",
        pathname: "",
        host: "",
        search: "",
        protocol: ""
      }
      win = {
        navigator: { userAgent: "", vendor: "", languages: [] },
        document: {
          location: loc,
          title: ""
        },
        location: loc,
        screen: {
          width: "",
          height: ""
        }
      }
    } else {
      win = global
    }

    const result = UAParser.parse(win.navigator.userAgent)

    const browser = result.browser
    const os = result.os
    const device = result.device
    const platform = !device.type ? "Web" : device.type === "mobile" ? "Mobile" : "ETC"
    const _protocol = win.location.protocol || ""
    const protocol = _protocol.replace(":", "")
    const screen = win.screen
    let timezone = ""
    try {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (e) {
    }

    return {
      platform: platform || "",
      browserName: browser.name || "",
      browserMajorVersion: browser.major || "",
      browserVersion: browser.version || "",
      osName: os.name || "",
      osVersion: os.version || "",
      deviceModel: device.model || "",
      deviceType: device.type || "",
      deviceVendor: device.vendor || "",
      language: win.navigator.languages?.[0] || "",
      userAgent: win.navigator.userAgent || "",
      pagePath: win.location.pathname || "",
      pageTitle: win.document.title || "",
      host: win.location.host || "",
      protocol: protocol,
      referrer: win.document.referrer || "",
      queryParameter: win.location.search || "",
      screenWidth: screen.width || "",
      screenHeight: screen.height || "",
      timeZone: timezone || ""
    }
  }
}
