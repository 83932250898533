import EventDispatcher, { EventDispatcherResponse } from "./EventDispatcher"
import Event from "./Event"
import { DefaultEventQueue, EventQueue } from "./EventQueue"
import { IEventRepository } from "./EventRepository"
import { EventDto } from "./dto"
import { Emitter } from "../util/emitter"

export interface EventProcessorEventMap {
  queue: Event
}

export default interface EventProcessor extends Emitter<EventProcessorEventMap> {
  process(event: Event): void

  start(): Promise<void>

  flush(): void

  close(): void
}

export class DefaultEventProcessor extends Emitter<EventProcessorEventMap> implements EventProcessor {
  private eventDispatcher: EventDispatcher
  private flushInterval: number
  private queue: EventQueue<Event>
  private repository: IEventRepository<EventDto> | null

  constructor(
    eventDispatcher: EventDispatcher,
    batchSize: number,
    flushInterval: number,
    repository: IEventRepository<EventDto> | null = null
  ) {
    super()
    this.eventDispatcher = eventDispatcher
    this.flushInterval = flushInterval

    this.queue = new DefaultEventQueue(this.drainQueue.bind(this), batchSize, flushInterval)
    this.repository = repository
  }

  isClientError(xhr?: EventDispatcherResponse) {
    return !!(xhr && xhr.statusCode >= 400 && xhr.statusCode < 500)
  }

  drainRepository(useBeacon?: boolean) {
    return new Promise<void>(async (resolve) => {
      const dtos = await this.repository?.read()

      if (!dtos || dtos.length === 0) {
        resolve()
        return
      }

      if (useBeacon) {
        this.eventDispatcher.dispatchXhrOrBeacon(
          dtos,
          () => {
            resolve(this.repository?.delete(dtos))
          },
          (xhr) => {
            if (this.isClientError(xhr)) {
              resolve(this.repository?.delete(dtos))
              return
            }

            resolve()
          }
        )
      } else {
        this.eventDispatcher.dispatch(
          dtos,
          () => {
            resolve(this.repository?.delete(dtos))
          },
          (xhr) => {
            if (this.isClientError(xhr)) {
              resolve(this.repository?.delete(dtos))
              return
            }

            resolve()
          }
        )
      }
    })
  }

  drainQueue(buffer: Event[], useBeacon?: boolean): Promise<void> {
    const dtos = buffer.map((item) => item.toDto())

    const reqPromise = new Promise<void>(async (resolve) => {
      if (buffer.length === 0) {
        resolve()
        return
      }

      if (useBeacon) {
        // closing queue, add to repository first in case of fast close
        this.repository?.add(dtos)

        this.eventDispatcher.dispatchXhrOrBeacon(
          buffer,
          () => {
            resolve(this.repository?.delete(dtos))
          },
          (xhr) => {
            if (this.isClientError(xhr)) {
              resolve(this.repository?.delete(dtos))
              return
            }

            resolve()
          }
        )
      } else {
        this.eventDispatcher.dispatch(
          buffer,
          () => {
            resolve()
          },
          (xhr) => {
            if (this.isClientError(xhr)) {
              resolve()
              return
            }

            resolve(this.repository?.add(dtos))
          }
        )
      }
    })

    return reqPromise
  }

  process(event: Event): void {
    this.emit("queue", event)
    this.queue.enqueue(event)
  }

  async start(): Promise<void> {
    this.queue.start()
  }

  stop(): Promise<any> {
    try {
      this.queue.close()
    } catch (e) {}
    return Promise.resolve()
  }

  flush(): void {
    this.queue.flush()
  }

  close(): void {
    this.stop()
  }
}
