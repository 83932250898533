import { TimeUnit } from "../../util/TimeUtil"
import { Measurement, MetricId } from "../Metric"
import { Timer } from "../Timer"

export class NoopTimer implements Timer {
  public id: MetricId

  constructor(id: MetricId) {
    this.id = id
  }

  measure(): Measurement[] {
    return [
      new Measurement("count", this.count.bind(this)),
      new Measurement("total", () => this.totalTime("milliseconds")),
      new Measurement("max", () => this.max("milliseconds")),
      new Measurement("mean", () => this.mean("milliseconds"))
    ]
  }

  count() {
    return 0
  }

  totalTime(unit: TimeUnit) {
    return 0
  }

  max(unit: TimeUnit) {
    return 0
  }

  mean(unit: TimeUnit) {
    return 0
  }

  record(amount: number, unit: TimeUnit) {}
}
