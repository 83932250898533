import { Segment, Target, TargetCondition } from "../../model/model"
import ConditionMatcher from "./ConditionMatcher"
import { EvaluatorContext, EvaluatorRequest } from "../evalautor/Evaluator"
import UserConditionMatcher from "./UserConditionMatcher"

export default class SegmentConditionMatcher implements ConditionMatcher {
  private readonly segmentMatcher: SegmentMatcher

  constructor(segmentMatcher: SegmentMatcher) {
    this.segmentMatcher = segmentMatcher
  }

  matches(request: EvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    if (condition.key.type != "SEGMENT") {
      throw new Error(`Unsupported TargetKeyType [${condition.key.type}]`)
    }

    const isMatched = condition.match.values.some((it) => this._matches(request, context, it))

    switch (condition.match.type) {
      case "MATCH":
        return isMatched
      case "NOT_MATCH":
        return !isMatched
    }
  }

  private _matches(request: EvaluatorRequest, context: EvaluatorContext, value: any): boolean {
    if (typeof value !== "string") {
      throw new Error(`SegmentKey[${value}]`)
    }
    const segment = request.workspace.getSegmentOrNull(value)
    if (!segment) {
      throw new Error(`Segment[${value}]`)
    }
    return this.segmentMatcher.matches(request, context, segment)
  }
}

export class SegmentMatcher {
  private userConditionMatcher: UserConditionMatcher

  constructor(userConditionMatcher: UserConditionMatcher) {
    this.userConditionMatcher = userConditionMatcher
  }

  matches(request: EvaluatorRequest, context: EvaluatorContext, segment: Segment): boolean {
    return segment.targets.some((it) => this._matches(request, context, it))
  }

  private _matches(request: EvaluatorRequest, context: EvaluatorContext, target: Target): boolean {
    return target.conditions.every((it) => this.userConditionMatcher.matches(request, context, it))
  }
}
