export type TimeUnit = "nanoseconds" | "microseconds" | "milliseconds" | "seconds"

export class TimeUtil {
  private static C0 = 1
  private static C1 = TimeUtil.C0 * 1000
  private static C2 = TimeUtil.C1 * 1000
  private static C3 = TimeUtil.C2 * 1000

  static converter: Record<TimeUnit, (second: number, unit: TimeUnit) => number> = {
    nanoseconds: TimeUtil.nanosToUnit,
    microseconds: TimeUtil.microsToUnit,
    milliseconds: TimeUtil.millisToUnit,
    seconds: TimeUtil.secondsToUnit
  }

  static nanosToUnit(nanos: number, unit: TimeUnit) {
    switch (unit) {
      case "microseconds":
        return nanos / (TimeUtil.C1 / TimeUtil.C0)
      case "milliseconds":
        return nanos / (TimeUtil.C2 / TimeUtil.C0)
      case "seconds":
        return nanos / (TimeUtil.C3 / TimeUtil.C0)
      case "nanoseconds":
      default:
        return nanos
    }
  }

  static microsToUnit(micros: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return micros * (TimeUtil.C1 / TimeUtil.C0)
      case "milliseconds":
        return micros / (TimeUtil.C2 / TimeUtil.C1)
      case "seconds":
        return micros / (TimeUtil.C3 / TimeUtil.C1)
      case "microseconds":
      default:
        return micros
    }
  }

  static millisToUnit(millis: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return millis * (TimeUtil.C2 / TimeUtil.C0)
      case "microseconds":
        return millis * (TimeUtil.C2 / TimeUtil.C1)
      case "seconds":
        return millis / (TimeUtil.C3 / TimeUtil.C2)
      case "milliseconds":
      default:
        return millis
    }
  }

  static secondsToUnit(seconds: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return seconds * (TimeUtil.C3 / TimeUtil.C0)
      case "microseconds":
        return seconds * (TimeUtil.C3 / TimeUtil.C1)
      case "milliseconds":
        return seconds * (TimeUtil.C3 / TimeUtil.C2)
      case "seconds":
      default:
        return seconds
    }
  }
}

export interface Clock {
  tick(): number

  currentMillis(): number
}

export class SystemClock implements Clock {
  static instance = new SystemClock()

  tick(): number {
    return Date.now() * 1e3
  }

  currentMillis(): number {
    return Date.now()
  }
}
