import TargetMatcher from "../match/TargetMatcher"
import ActionResolver from "../action/ActionResolver"
import { Variation } from "../../model/model"
import { ManualOverrideStorage } from "./ManualOverrideStorage"
import ExperimentRequest from "../evalautor/experiment/ExperimentRequest"
import { EvaluatorContext } from "../evalautor/Evaluator"

export default class OverrideResolver {
  private readonly manualOverrideStorage: ManualOverrideStorage
  private readonly targetMatcher: TargetMatcher
  private readonly actionResolver: ActionResolver

  constructor(
    manualOverrideStorage: ManualOverrideStorage,
    targetMatcher: TargetMatcher,
    actionResolver: ActionResolver
  ) {
    this.manualOverrideStorage = manualOverrideStorage
    this.targetMatcher = targetMatcher
    this.actionResolver = actionResolver
  }

  resolveOrNull(request: ExperimentRequest, context: EvaluatorContext): Variation | undefined {
    return (
      this.resolveManualOverride(request) ??
      this._resolveUserOverride(request) ??
      this._resolveSegmentOverride(request, context)
    )
  }

  private resolveManualOverride(request: ExperimentRequest): Variation | undefined {
    return this.manualOverrideStorage.getVariation(request.experiment, request.user)
  }

  _resolveUserOverride(request: ExperimentRequest): Variation | undefined {
    const experiment = request.experiment
    const identifier = request.user.identifiers[experiment.identifierType]
    if (!identifier) {
      return undefined
    }

    const overriddenVariationId = experiment.userOverrides.get(identifier)
    if (!overriddenVariationId) {
      return undefined
    }

    return experiment._getVariationByIdOrNull(overriddenVariationId)
  }

  _resolveSegmentOverride(request: ExperimentRequest, context: EvaluatorContext): Variation | undefined {
    const overriddenRule = request.experiment.segmentOverrides.find((it) =>
      this.targetMatcher.matches(request, context, it.target)
    )
    if (!overriddenRule) {
      return undefined
    }

    return this.actionResolver.resolveOrNull(request, context, overriddenRule.action)
  }
}
