import Event from "./Event"

export type EventQueueSink<T extends Event = Event> = (buffer: T[], useBeacon?: boolean) => Promise<any>

export interface EventQueue<T extends Event = Event> {
  start(): void

  enqueue(event: T): void

  flush(): void

  close(): void
}

class Timer {
  private timeout: number
  private callback: () => void
  private timeoutId?: number

  constructor({ timeout, callback }: { timeout: number; callback: () => void }) {
    this.timeout = Math.max(timeout, 0)
    this.callback = callback
  }

  start(): void {
    this.timeoutId = setTimeout(this.callback, this.timeout) as any
  }

  refresh(): void {
    this.stop()
    this.start()
  }

  stop(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId as any)
    }
  }
}

export class DefaultEventQueue<T extends Event = Event> implements EventQueue<T> {
  public timer: Timer
  private buffer: T[]
  private maxQueueSize: number
  private sink: EventQueueSink<T>

  private started: boolean

  constructor(sink: EventQueueSink<T>, batchSize: number, flushInterval: number) {
    this.buffer = []
    this.sink = sink
    this.maxQueueSize = batchSize
    this.timer = new Timer({
      callback: this.flush.bind(this),
      timeout: flushInterval
    })
    this.started = false
  }

  start(): void {
    this.started = true
  }

  stop(): Promise<any> {
    this.started = false
    const result = this.sink(this.buffer, true)
    this.buffer = []
    this.timer.stop()
    return result
  }

  enqueue(event: T): void {
    if (!this.started) {
      return
    }

    if (this.buffer.length === 0) {
      this.timer.refresh()
    }

    this.buffer.push(event)

    if (this.buffer.length >= this.maxQueueSize) {
      this.flush()
    }
  }

  flush(): void {
    this.sink(this.buffer)
    this.buffer = []
    this.timer.stop()
  }

  close() {
    this.stop()
  }
}
