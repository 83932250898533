import { TimeUnit } from "../../util/TimeUtil"
import { CumulativeTimer } from "../cumulative/CumulativeTimer"
import { Metric, MetricId, Measurement } from "../Metric"
import { Timer } from "../Timer"
import { FlushMetric } from "./FlushMetric"

export class FlushTimer implements FlushMetric, Timer {
  public id: MetricId
  private currentTimer: Timer

  constructor(id: MetricId) {
    this.id = id
    this.currentTimer = new CumulativeTimer(id)
  }

  flush(): Metric {
    const prev = this.currentTimer
    this.currentTimer = new CumulativeTimer(this.id)
    return prev
  }

  count(): number {
    return this.currentTimer.count()
  }

  totalTime(unit: TimeUnit): number {
    return this.currentTimer.totalTime(unit)
  }

  max(unit: TimeUnit): number {
    return this.currentTimer.max(unit)
  }

  record(amount: number, unit: TimeUnit): void {
    this.currentTimer.record(amount, unit)
  }

  mean(unit: TimeUnit): number {
    return this.count() === 0 ? 0.0 : this.totalTime(unit) / this.count()
  }

  measure(): Measurement[] {
    return [
      new Measurement("count", this.count.bind(this)),
      new Measurement("total", () => this.totalTime("milliseconds")),
      new Measurement("max", () => this.max("milliseconds")),
      new Measurement("mean", () => this.mean("milliseconds"))
    ]
  }
}
