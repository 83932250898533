import { v4 as uuid4 } from "uuid"
import { Comparable } from "../util/Comparable"
import { CounterBuilder, Counter } from "./Counter"
import { Metric, isMetricIdEqual, MetricId } from "./Metric"
import { Timer, TimerBuilder } from "./Timer"

export abstract class MetricRegistry {
  id = uuid4()
  name: string = "MetricRegistry"

  private _metrics: Comparable<MetricId, Metric> = new Comparable(isMetricIdEqual)

  get metrics() {
    return Array.from(this._metrics.values())
  }

  registerCounter(id: MetricId): Counter {
    return this.registerMetricIfNecessary(id, this.createCounter.bind(this))
  }

  registerTimer(id: MetricId): Timer {
    return this.registerMetricIfNecessary(id, this.createTimer.bind(this))
  }

  counter(name: string, tags: { [key: string]: string } = {}) {
    return new CounterBuilder(name).tags(tags).register(this)
  }

  timer(name: string, tags: { [key: string]: string } = {}) {
    return new TimerBuilder(name).tags(tags).register(this)
  }

  abstract createCounter(id: MetricId): Counter
  abstract createTimer(id: MetricId): Timer

  private registerMetricIfNecessary<T extends Metric>(id: MetricId, create: (id: MetricId) => Metric): T {
    return this.getOrCreateMetric(id, create) as T
  }

  private getOrCreateMetric(id: MetricId, create: (id: MetricId) => Metric): Metric {
    let metric: Metric

    const registeredMetric = this._metrics.get(id)
    if (registeredMetric) {
      metric = registeredMetric
    } else {
      metric = create(id)
      this._metrics.add(id, metric)
    }

    return metric
  }

  close() {}
}
