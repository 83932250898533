import Logger from "../../core/internal/logger"
import { Headers, Response } from "../../core/internal/http"
import { SDK_KEY_HEADER, SDK_NAME_HEADER, SDK_VERSION_HEADER } from "../../config"

const log = Logger.log

function getRequest(requestUrl: string, headers: Headers) {
  const responsePromise = new Promise<Response>((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open("GET", requestUrl)

    xhr.onreadystatechange = function () {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 0) {
          reject(new Error("Request error"))
          return
        }

        resolve({
          statusCode: xhr.status,
          body: xhr.responseText
        })
      }
    }

    xhr.onerror = function () {
      reject()
    }

    xhr.setRequestHeader("content-type", "application/json")
    setHeaders(xhr, headers)
    xhr.send()
  })

  return {
    abort(): void {
      //request.abort()
    },
    responsePromise
  }
}

function postRequest(requestUrl: string, data: any, headers: Headers, callback: any, errorCallback?: any) {
  const dataString = JSON.stringify(data)

  const xhr = new XMLHttpRequest()
  xhr.open("POST", requestUrl)
  xhr.onreadystatechange = function () {
    if (xhr.readyState === xhr.DONE) {
      if (xhr.status >= 200 && xhr.status < 400) {
        callback({ statusCode: xhr.status })
      } else if (errorCallback) {
        errorCallback({ statusCode: xhr.status })
      }
    }
  }

  xhr.setRequestHeader("content-type", "application/json")
  setHeaders(xhr, headers)
  xhr.send(dataString)
}

function setHeaders(xhr: XMLHttpRequest, headers: Headers) {
  for (const name in headers) {
    if (headers.hasOwnProperty(name)) {
      xhr.setRequestHeader(name, headers[name])
    }
  }
}

function sendBeacon(requestUrl: string, data: any, headers: Headers, callback: any, errorCallback: any) {
  let beaconSupport = false

  // @ts-ignore
  if (typeof window !== "undefined") {
    // @ts-ignore
    beaconSupport = window && window.navigator && window.navigator.sendBeacon && true
  }

  if (beaconSupport) {
    const dataString = JSON.stringify(data)

    const params = new URLSearchParams()
    params.append("sdkName", headers[SDK_NAME_HEADER])
    params.append("sdkVersion", headers[SDK_VERSION_HEADER])
    params.append("sdkKey", headers[SDK_KEY_HEADER])
    params.append("data", dataString)

    const isQueued = window.navigator.sendBeacon(requestUrl, params)
    if (!isQueued) {
      log.error("sendBeacon failed to queue request.")
      errorCallback()
      return
    }

    callback()
  } else {
    log.error("Does not support sendBeacon")
    errorCallback()
  }
}

export default {
  getRequest,
  postRequest,
  sendBeacon
}
