import ContextualEvaluator from "../ContextualEvaluator"
import RemoteConfigRequest from "./RemoteConfigRequest"
import RemoteConfigEvaluation from "./RemoteConfigEvaluation"
import { EvaluatorContext, EvaluatorRequest } from "../Evaluator"
import RemoteConfigParameterTargetRuleDeterminer from "../../target/RemoteConfigParameterTargetRuleDeterminer"
import { DecisionReason, Properties, RemoteConfigParameterValue } from "../../../model/model"
import ObjectUtil from "../../../util/ObjectUtil"

export default class RemoteConfigEvaluator extends ContextualEvaluator<RemoteConfigRequest, RemoteConfigEvaluation> {
  private readonly targetRuleDeterminer: RemoteConfigParameterTargetRuleDeterminer

  constructor(targetRuleDeterminer: RemoteConfigParameterTargetRuleDeterminer) {
    super()
    this.targetRuleDeterminer = targetRuleDeterminer
  }

  supports(request: EvaluatorRequest): request is RemoteConfigRequest {
    return (request as RemoteConfigRequest).parameter !== undefined
  }

  protected evaluateInternal(request: RemoteConfigRequest, context: EvaluatorContext): RemoteConfigEvaluation {
    let properties: Properties = {}
    properties["requestValueType"] = request.requiredType
    properties["requestDefaultValue"] = request.defaultValue

    if (ObjectUtil.isNullOrUndefined(request.user.identifiers[request.parameter.identifierType])) {
      return RemoteConfigEvaluation.ofDefault(request, context, DecisionReason.IDENTIFIER_NOT_FOUND, properties)
    }

    const targetRule = this.targetRuleDeterminer.determineTargetRuleOrNull(request, context)
    if (ObjectUtil.isNotNullOrUndefined(targetRule)) {
      properties["targetRuleKey"] = targetRule.key
      properties["targetRuleName"] = targetRule.name
      return this.evaluation(request, context, targetRule.value, DecisionReason.TARGET_RULE_MATCH, properties)
    }

    return this.evaluation(request, context, request.parameter.defaultValue, DecisionReason.DEFAULT_RULE, properties)
  }

  private evaluation(
    request: RemoteConfigRequest,
    context: EvaluatorContext,
    parameterValue: RemoteConfigParameterValue,
    reason: string,
    properties: Properties
  ): RemoteConfigEvaluation {
    if (
      request.requiredType === "UNKNOWN" ||
      (request.requiredType !== "NULL" && typeof request.defaultValue !== typeof parameterValue.rawValue)
    ) {
      return RemoteConfigEvaluation.ofDefault(request, context, DecisionReason.TYPE_MISMATCH, properties)
    }

    return RemoteConfigEvaluation.of(request, context, parameterValue.id, parameterValue.rawValue, reason, properties)
  }
}
