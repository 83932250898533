import ValueOperatorMatcher from "./ValueOperatorMatcher"
import { HackleUser, TargetCondition, TargetKey } from "../../model/model"
import ObjectUtil from "../../util/ObjectUtil"
import ConditionMatcher from "./ConditionMatcher"
import { EvaluatorContext, EvaluatorRequest } from "../evalautor/Evaluator"

export default class UserConditionMatcher implements ConditionMatcher {
  private readonly userValueResolver: UserValueResolver
  private readonly valueOperatorMatcher: ValueOperatorMatcher

  constructor(userValueResolver: UserValueResolver, valueOperatorMatcher: ValueOperatorMatcher) {
    this.userValueResolver = userValueResolver
    this.valueOperatorMatcher = valueOperatorMatcher
  }

  matches(request: EvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    const userValue = this.userValueResolver.resolveOrNull(request.user, condition.key)
    if (ObjectUtil.isNullOrUndefined(userValue)) {
      return false
    }
    return this.valueOperatorMatcher.matches(userValue, condition.match)
  }
}

export class UserValueResolver {
  resolveOrNull(user: HackleUser, key: TargetKey): any | undefined {
    switch (key.type) {
      case "USER_ID":
        return user.identifiers[key.name]
      case "USER_PROPERTY":
        return user.properties[key.name]
      case "HACKLE_PROPERTY":
        return user.hackleProperties[key.name]
      case "SEGMENT":
      case "AB_TEST":
      case "FEATURE_FLAG":
      case "EVENT_PROPERTY":
        throw new Error(`Unsupported TargetKeyType [${key.type}]`)
    }
  }
}
