import Evaluator, { EvaluatorContext, EvaluatorEvaluation, EvaluatorRequest } from "./Evaluator"
import ContextualEvaluator from "./ContextualEvaluator"
import ObjectUtil from "../../util/ObjectUtil"

export default class DelegatingEvaluator implements Evaluator {
  private readonly evaluators = new Array<ContextualEvaluator<EvaluatorRequest, EvaluatorEvaluation>>()

  add(evaluator: ContextualEvaluator<EvaluatorRequest, EvaluatorEvaluation>) {
    this.evaluators.push(evaluator)
  }

  evaluate(request: EvaluatorRequest, context: EvaluatorContext): EvaluatorEvaluation {
    const evaluator = this.evaluators.find((it) => it.supports(request))
    if (ObjectUtil.isNullOrUndefined(evaluator)) {
      throw new Error(`Unsupported EvaluatorRequest [${request.toString()}]`)
    }
    return evaluator.evaluate(request, context)
  }
}
