import { Comparable } from "../../util/Comparable"
import { TimeUnit, TimeUtil } from "../../util/TimeUtil"
import { MetricId, Measurement } from "../Metric"
import { MetricRegistry } from "../MetricRegistry"
import { NoopTimer } from "../noop/NoopTimer"
import { Timer } from "../Timer"
import { DelegatingMetric } from "./DelegatingMetric"

export class DelegatingTimer implements DelegatingMetric, Timer {
  public id: MetricId
  private noopTimer
  private _timers: Comparable<MetricRegistry, Timer> = new Comparable((a, b) => a.id === b.id)

  constructor(id: MetricId) {
    this.id = id
    this.noopTimer = new NoopTimer(id)
  }

  private get firstTimer() {
    return this.timers[0] || this.noopTimer
  }

  totalTime(unit: TimeUnit): number {
    return this.firstTimer.totalTime(unit)
  }

  max(unit: TimeUnit): number {
    return this.firstTimer.max(unit)
  }

  mean(unit: TimeUnit): number {
    return this.firstTimer.mean(unit)
  }

  record(amount: number, unit: TimeUnit): void {
    this.timers.forEach((timer) => {
      timer.record(amount, unit)
    })
  }

  get timers() {
    return Array.from(this._timers.values())
  }

  add(registry: MetricRegistry): void {
    this._timers.add(registry, registry.registerTimer(this.id))
  }

  count(): number {
    return this.firstTimer.count()
  }

  measure(): Measurement[] {
    return [
      new Measurement("count", this.count.bind(this)),
      new Measurement("total", () => this.totalTime("milliseconds")),
      new Measurement("max", () => this.max("milliseconds")),
      new Measurement("mean", () => this.mean("milliseconds"))
    ]
  }
}
