import { DecisionReason } from "../../model/model"
import EvaluationFlow from "./EvaluationFlow"
import ExperimentTargetDeterminer from "../target/ExperimentTargetDeterminer"
import ActionResolver from "../action/ActionResolver"
import TargetRuleDeterminer from "../target/TargetRuleDeterminer"
import OverrideResolver from "../target/OverrideResolver"
import ContainerResolver from "../container/ContainerResolver"
import ExperimentRequest from "../evalautor/experiment/ExperimentRequest"
import { EvaluatorContext } from "../evalautor/Evaluator"
import ExperimentEvaluation from "../evalautor/experiment/ExperimentEvaluation"

export default interface FlowEvaluator {
  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation
}

export class OverrideEvaluator implements FlowEvaluator {
  private readonly overrideResolver: OverrideResolver

  constructor(overrideResolver: OverrideResolver) {
    this.overrideResolver = overrideResolver
  }

  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    const overriddenVariation = this.overrideResolver.resolveOrNull(request, context)
    if (overriddenVariation) {
      switch (request.experiment.type) {
        case "AB_TEST":
          return ExperimentEvaluation.of(request, context, overriddenVariation, DecisionReason.OVERRIDDEN)
        case "FEATURE_FLAG":
          return ExperimentEvaluation.of(request, context, overriddenVariation, DecisionReason.INDIVIDUAL_TARGET_MATCH)
      }
    } else {
      return nextFlow.evaluate(request, context)
    }
  }
}

export class DraftEvaluator implements FlowEvaluator {
  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    if (request.experiment.status === "DRAFT") {
      return ExperimentEvaluation.ofDefault(request, context, DecisionReason.EXPERIMENT_DRAFT)
    } else {
      return nextFlow.evaluate(request, context)
    }
  }
}

export class PausedEvaluator implements FlowEvaluator {
  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    if (request.experiment.status === "PAUSED") {
      switch (request.experiment.type) {
        case "AB_TEST":
          return ExperimentEvaluation.ofDefault(request, context, DecisionReason.EXPERIMENT_PAUSED)
        case "FEATURE_FLAG":
          return ExperimentEvaluation.ofDefault(request, context, DecisionReason.FEATURE_FLAG_INACTIVE)
      }
    } else {
      return nextFlow.evaluate(request, context)
    }
  }
}

export class CompletedEvaluator implements FlowEvaluator {
  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    if (request.experiment.status === "COMPLETED") {
      const winnerVariation = request.experiment._winnerVariationOrNull()
      if (!winnerVariation) {
        throw new Error(`winner variation [${request.experiment.id}]`)
      }
      return ExperimentEvaluation.of(request, context, winnerVariation, DecisionReason.EXPERIMENT_COMPLETED)
    } else {
      return nextFlow.evaluate(request, context)
    }
  }
}

export class ExperimentTargetEvaluator implements FlowEvaluator {
  private readonly experimentTargetDeterminer: ExperimentTargetDeterminer

  constructor(experimentTargetDeterminer: ExperimentTargetDeterminer) {
    this.experimentTargetDeterminer = experimentTargetDeterminer
  }

  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    if (request.experiment.type !== "AB_TEST") {
      throw new Error(`experiment type must be AB_TEST [${request.experiment.id}]`)
    }

    const isUserInExperimentTarget = this.experimentTargetDeterminer.isUserInExperimentTarget(request, context)
    if (isUserInExperimentTarget) {
      return nextFlow.evaluate(request, context)
    } else {
      return ExperimentEvaluation.ofDefault(request, context, DecisionReason.NOT_IN_EXPERIMENT_TARGET)
    }
  }
}

export class TrafficAllocateEvaluator implements FlowEvaluator {
  private readonly actionResolver: ActionResolver

  constructor(actionResolver: ActionResolver) {
    this.actionResolver = actionResolver
  }

  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    if (request.experiment.status !== "RUNNING") {
      throw new Error(`experiment status must be RUNNING [${request.experiment.id}]`)
    }

    if (request.experiment.type !== "AB_TEST") {
      throw new Error(`experiment type must be AB_TEST [${request.experiment.id}]`)
    }

    const variation = this.actionResolver.resolveOrNull(request, context, request.experiment.defaultRule)
    if (!variation) {
      return ExperimentEvaluation.ofDefault(request, context, DecisionReason.TRAFFIC_NOT_ALLOCATED)
    }

    if (variation.isDropped) {
      return ExperimentEvaluation.ofDefault(request, context, DecisionReason.VARIATION_DROPPED)
    }

    return ExperimentEvaluation.of(request, context, variation, DecisionReason.TRAFFIC_ALLOCATED)
  }
}

export class TargetRuleEvaluator implements FlowEvaluator {
  private readonly targetRuleDeterminer: TargetRuleDeterminer
  private readonly actionResolver: ActionResolver

  constructor(targetRuleDeterminer: TargetRuleDeterminer, actionResolver: ActionResolver) {
    this.targetRuleDeterminer = targetRuleDeterminer
    this.actionResolver = actionResolver
  }

  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    if (request.experiment.status !== "RUNNING") {
      throw new Error(`experiment status must be RUNNING [${request.experiment.id}]`)
    }

    if (request.experiment.type !== "FEATURE_FLAG") {
      throw new Error(`experiment type must be FEATURE_FLAG [${request.experiment.id}]`)
    }

    if (!request.user.identifiers[request.experiment.identifierType]) {
      return nextFlow.evaluate(request, context)
    }

    const targetRule = this.targetRuleDeterminer.determineTargetRuleOrNull(request, context)

    if (!targetRule) {
      return nextFlow.evaluate(request, context)
    }

    const variation = this.actionResolver.resolveOrNull(request, context, targetRule.action)
    if (!variation) {
      throw new Error(`FeatureFlag must decide the Variation [${request.experiment.id}]`)
    }
    return ExperimentEvaluation.of(request, context, variation, DecisionReason.TARGET_RULE_MATCH)
  }
}

export class DefaultRuleEvaluator implements FlowEvaluator {
  private readonly actionResolver: ActionResolver

  constructor(actionResolver: ActionResolver) {
    this.actionResolver = actionResolver
  }

  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    if (request.experiment.status !== "RUNNING") {
      throw new Error(`experiment status must be RUNNING [${request.experiment.id}]`)
    }

    if (request.experiment.type !== "FEATURE_FLAG") {
      throw new Error(`experiment type must be FEATURE_FLAG [${request.experiment.id}]`)
    }

    if (!request.user.identifiers[request.experiment.identifierType]) {
      return ExperimentEvaluation.ofDefault(request, context, DecisionReason.DEFAULT_RULE)
    }

    const variation = this.actionResolver.resolveOrNull(request, context, request.experiment.defaultRule)
    if (!variation) {
      throw new Error(`FeatureFlag must decide the Variation [${request.experiment.id}]`)
    }

    return ExperimentEvaluation.of(request, context, variation, DecisionReason.DEFAULT_RULE)
  }
}

export class ContainerEvaluator implements FlowEvaluator {
  private readonly containerResolver: ContainerResolver

  constructor(containerResolver: ContainerResolver) {
    this.containerResolver = containerResolver
  }

  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    const containerId = request.experiment.containerId
    if (!containerId) {
      return nextFlow.evaluate(request, context)
    }

    const container = request.workspace.getContainerOrNull(containerId)
    if (!container) {
      throw new Error(`container[${containerId}]`)
    }

    const isUserInContainerGroup = this.containerResolver.isUserInContainerGroup(request, context, container)
    if (isUserInContainerGroup) {
      return nextFlow.evaluate(request, context)
    } else {
      return ExperimentEvaluation.ofDefault(request, context, DecisionReason.NOT_IN_MUTUAL_EXCLUSION_EXPERIMENT)
    }
  }
}

export class IdentifierEvaluator implements FlowEvaluator {
  evaluate(request: ExperimentRequest, context: EvaluatorContext, nextFlow: EvaluationFlow): ExperimentEvaluation {
    if (request.user.identifiers[request.experiment.identifierType]) {
      return nextFlow.evaluate(request, context)
    } else {
      return ExperimentEvaluation.ofDefault(request, context, DecisionReason.IDENTIFIER_NOT_FOUND)
    }
  }
}
