import { Track } from "../../../event/Event"
import { DecisionReason } from "../../../model/model"
import { TrackInAppMessageDeterminer } from "../../iam/TrackInAppMessageDeterminer"
import ContextualEvaluator from "../ContextualEvaluator"
import { EvaluatorContext, EvaluatorRequest } from "../Evaluator"
import { TrackInAppMessageEvaluation } from "./TrackInAppMessageEvaluation"
import { TrackInAppMessageRequest } from "./TrackInAppMessageRequest"

export class TrackInAppMessageEvaluator extends ContextualEvaluator<
  TrackInAppMessageRequest,
  TrackInAppMessageEvaluation
> {
  constructor(private readonly determiner: TrackInAppMessageDeterminer) {
    super()
  }

  public supports(request: EvaluatorRequest): request is TrackInAppMessageRequest {
    return !!("track" in request && (request as TrackInAppMessageRequest).track instanceof Track)
  }

  public evaluateInternal(request: TrackInAppMessageRequest, context: EvaluatorContext): TrackInAppMessageEvaluation {
    const message = this.determiner.determineInAppMessage(request, context)

    if (!message) {
      return new TrackInAppMessageEvaluation(DecisionReason.DEFAULT_RULE, context.targetEvaluations, message)
    }

    return new TrackInAppMessageEvaluation(DecisionReason.TARGET_RULE_MATCH, context.targetEvaluations, message)
  }
}
