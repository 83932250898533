import { IAM } from "../../model/model"
import { EvaluatorContext } from "../evalautor/Evaluator"
import { InAppMessageRequest } from "../evalautor/iam/InAppMessageRequest"
import InAppMessageTargetMatcher from "../match/InAppMessageTargetMatcher"

export class InAppMessageDeterminer {
  constructor(private readonly targetMatcher: InAppMessageTargetMatcher) {}

  public determineInAppMessageTargetRule(request: InAppMessageRequest, context: EvaluatorContext) {
    return this.matches(request, context, request.message) ? request.message : undefined
  }

  private matches(request: InAppMessageRequest, context: EvaluatorContext, message: IAM) {
    // if not web targeted, ignore
    if (!message.supportsWeb()) {
      return false
    }

    // if override condition matches, show message
    if (
      message.targetContext.overrides.some((override) =>
        this.targetMatcher.matches(request, context, override.toTarget())
      )
    ) {
      return true
    }

    // if not in app message status active, ignore
    if (!message.isActive()) {
      return false
    }

    // if in app message not within date range, ignore
    if (!message.dateRange.within(request.timestamp)) {
      return false
    }

    if (message.targetContext.targets.length === 0) {
      return true
    }

    // check target rule conditions
    return message.targetContext.targets.some((target) => this.targetMatcher.matches(request, context, target))
  }
}
